import { useTranslation } from 'react-i18next';
import { YButton, YIcon } from '@hand-talk/yotta-components';
import hugoAndMaya from '../../../../assets/svg/hugoAndMaya.svg';
import { Container, Content, Header, ImageContainer, Paragraphs } from './styles';

interface ShareResultSlideProps {
  handleExport: () => void;
  isPrintActive?: boolean;
}

export const ShareResultSlide = ({ handleExport }: ShareResultSlideProps) => {
  const { t: translate } = useTranslation('ShareSlidePage');

  return (
    <Container>
      <Content>
        <Header>{translate('ShareTitle')}</Header>
        <Paragraphs>
          <p>{translate('ShareFirstBody')}</p>
          <p>{translate('ShareSecondBody')}</p>
        </Paragraphs>
        <YButton
          aria-label={translate('exportButtonLabel')}
          icon={<YIcon name="download" />}
          iconPosition="left"
          onClick={() => handleExport!()}
        >
          {translate('exportBtn')}
        </YButton>
      </Content>
      <ImageContainer>
        <img src={hugoAndMaya} alt={translate('HugoAndMayaAlt')} />
      </ImageContainer>
    </Container>
  );
};
