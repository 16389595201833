/** Libs */
import i18n from '../../../infra/i18n';
import { useTranslation } from 'react-i18next';
import { YHelpButton, YIcon } from '@hand-talk/yotta-components';

/** Components */
import CustomContainer from '../CustomContainer';
import Title from '../Title';
import CardHelper from '../CardHelper';

/** Utils */
import { Acumulative } from './Graphics';

/** Services */
import { isMobile, numberFormatter } from '../../../infra/services/helper';

/** Styçes */
import { ContainerTop, SmallDataCardStyled } from './styles';

interface IAccumulativeUsers {
  monthlyUsersInfo: { [key: string]: number };
  averageUsers: number;
}

const ChartUsers = ({ monthlyUsersInfo, averageUsers }: IAccumulativeUsers) => {
  const { t: translationChartUsers } = useTranslation('ChartUsers');
  const users = numberFormatter(averageUsers);

  return (
    <CustomContainer className="PrintOnly">
      <Title
        tag={'h2'}
        className={'TitleAll'}
        children={translationChartUsers('title')}
      />
      <CardHelper
        marginType={'customFooter'}
        title={translationChartUsers('users')}
        icon={<YIcon name="person" />}
        buttonHelp={
          <ContainerTop>
            <SmallDataCardStyled
              className={'SmallDataCardStyled'}
              text={translationChartUsers('averagePeriod')}
              value={users.toString()}
            />
            <YHelpButton
              content={translationChartUsers('usersByMonthDescription')}
              direction={isMobile() === true ? 'left' : 'bottom'}
              context={'dark'}
              display={'desktop'}
              alignment={'center'}
            />
          </ContainerTop>
        }
      >
        <Title
          tag={'h3'}
          className={'TitleSM'}
          children={translationChartUsers('usersByMonth')}
        />
        <Acumulative
          monthlyUsersInfo={monthlyUsersInfo}
          translation={translationChartUsers}
          language={i18n.language}
        />
      </CardHelper>
    </CustomContainer>
  );
};

export default ChartUsers;
