/** Libs */
import jsZip from 'jszip';

/** Interfaces */
import { ICsvLabels, ITableItem, IPeriod, IReportSchema, Report } from './interfaces';

import { formatPeriodSimple } from './helper';

type TDataArray = [string, number][];

type TMetric = {
  data: ITableItem[] | undefined;
  label: string;
  period?: IPeriod;
};

const csvContent = (dataArray: TDataArray): string => {
  return dataArray.map((rowArray) => rowArray.join(',')).join('\r\n');
};

const csvToZip = (zip: jsZip, filename: string, content: string): void => {
  zip.file(filename, content);
};

const csvArray = (dailyInfo: Record<string, number>): [TDataArray, TDataArray] => {
  const diary: TDataArray = [];
  const cumulative: TDataArray = [];
  let accumulated = 0;

  Object.entries(dailyInfo).forEach(([day, translations]) => {
    accumulated += translations;
    const dayFormat = `${day.slice(0, 4)}-${day.slice(4, 6)}-${day.slice(6)}`;
    diary.push([dayFormat, translations]);
    cumulative.push([dayFormat, accumulated]);
  });

  return [diary, cumulative];
};

const getMetrics = (report: IReportSchema, csvLabels: ICsvLabels): TMetric[] => {
  const {
    insight: { topPages, topStates, topDevices, topAddons },
  } = report;
  const { csvTopPages, csvTopStates, csvTopDevices, csvTopAddons } = csvLabels;

  return [
    { data: topPages, label: csvTopPages },
    { data: topStates, label: csvTopStates },
    { data: topDevices, label: csvTopDevices },
    { data: topAddons, label: csvTopAddons },
  ].filter((metric) => metric.data);
};

export const exportCsv = (report: Report, csvLabels: ICsvLabels): void => {
  const zip = new jsZip();
  const { period, translations } = report;
  const { csvTitle, csvDiary, csvCumulative } = csvLabels;
  const newPeriod = formatPeriodSimple(period);

  Object.entries(translations.signLanguage).forEach(([languageKey, languageData]) => {
    const dailyInfo = languageData.dailyInfo;
    const [diary, cumulative] = csvArray(dailyInfo);

    csvToZip(zip, `${csvDiary}-${languageKey}-${newPeriod}.csv`, csvContent(diary));
    csvToZip(
      zip,
      `${csvCumulative}-${languageKey}-${newPeriod}.csv`,
      csvContent(cumulative)
    );
  });

  const metrics = getMetrics(report as IReportSchema, csvLabels);

  metrics.forEach(({ data, label, period }) => {
    const periodFormatted = period ? formatPeriodSimple(period) : newPeriod;

    if (!data || data.length === 0) return;

    csvToZip(
      zip,
      `${label}-${periodFormatted}.csv`,
      csvContent((data ?? []).map((item) => [item.source, item.value]))
    );
  });

  zip.generateAsync({ type: 'base64' }).then(function (base64) {
    const download = document.createElement('a');
    download.setAttribute('href', 'data:application/zip;base64,' + base64);
    download.setAttribute('download', `${csvTitle}-${newPeriod}.zip`);
    document.body.appendChild(download);
    download.click();
  });
};
