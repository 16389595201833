/** Libs */
import React from 'react';
import { YIcon, YIconButton } from '@hand-talk/yotta-components';

/** Styles */
import { Content, Footer, Header, Overlay, Title } from './styles';

/** Hooks */
import useAccessibleFocusModal from '../../hooks/useAccessibleFocusModal';
import useBodyScrollLock from '../../hooks/useBodyScrollLock';

interface IYModal {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  hasFooter?: boolean;
  onClose: () => void;
  closeButtonAriaLabel: string;
  size?: 'lg' | 'md' | 'sm';
}

const YModal: React.FC<IYModal> = ({
  title,
  children,
  isOpen,
  hasFooter,
  onClose,
  closeButtonAriaLabel,
  size = 'lg',
}) => {
  const { modalRef, titleRef } = useAccessibleFocusModal({ isOpen, onClose });
  useBodyScrollLock(isOpen);
  if (!isOpen) return null;

  return (
    <Overlay role="dialog" aria-modal="true" aria-labelledby="modal-title" ref={titleRef}>
      <Content ref={modalRef} tabIndex={-1} role="document" size={size}>
        <Header>
          {title && (
            <Title id="modal-title" tabIndex={-1}>
              {title}
            </Title>
          )}
          <YIconButton
            onClick={onClose}
            children={<YIcon name="close" />}
            title={closeButtonAriaLabel}
            aria-label={closeButtonAriaLabel}
          />
        </Header>
        {children}
        {hasFooter && <Footer />}
      </Content>
    </Overlay>
  );
};

export default YModal;
