// Libs
import React from 'react';
import { useInjection } from 'inversify-react';

// Interfaces
import { IPeriod, Language, AverageReport } from '../../infra/services/interfaces';

// Repositories
import AverageReportRepository from '../../infra/repositories/AverageReportRepository';

interface IState {
  averageReport?: AverageReport;
  isLoading?: boolean;
}

/**
 * Hook para obter os dados do relatório
 */
export default function useAverageReport() {
  const averageReportRepository = useInjection(AverageReportRepository);
  const [state, setState] = React.useState<IState>({});

  const handleError = (error: unknown) => {
    console.error(error);
    if (window.location.pathname !== '/not-found') {
      window.location.href = '/not-found';
    }
  };

  /**
   * Obtém um relatório utilizando a chave de acesso
   */
  const filterAverageReport = React.useCallback(
    async (accessKey: string, period: IPeriod, language: Language) => {
      setState((prev) => ({
        isLoading: true,
      }));
      try {
        const averageReport = await averageReportRepository.filter(
          accessKey,
          period,
          language
        );
        setState((prev) => ({
          isLoading: false,
          averageReport,
        }));
      } catch (error) {
        handleError(error);
      }
    },
    []
  );

  return { ...state, filterAverageReport };
}
