import styled from 'styled-components';
import {
  mobileMediaQuery,
  smallMobileMediaQuery,
} from '../../../../../infra/services/helper';

export const Container = styled.div<{ isPrintActive?: boolean }>`
  min-height: calc(100vh - 60px);

  display: flex;
  gap: ${({ theme }) => theme.spacing[7]};
  align-items: center;

  padding: ${({ theme }) => theme.spacing[6]};
  padding-bottom: 0;

  ${({ isPrintActive }) =>
    isPrintActive &&
    `
    width: 100%;
    
    flex-direction: column;
  `};

  ${mobileMediaQuery(`
    flex-direction: column;
    justify-content: space-between;

    padding-left: 0;
    padding-right: 0;
  `)};
`;

export const ImageLeftContainer = styled.div`
  img,
  svg {
    max-height: 500px;
  }

  ${mobileMediaQuery(`
    display: none;
  `)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[7]};
  align-items: center;

  ${({ theme }) =>
    smallMobileMediaQuery(`
    max-width: 250px;
  `)};
`;

export const Header = styled.h3`
  max-width: 350px;

  font: ${({ theme }) => theme.typography.display.lg};
  font-size: ${({ theme }) => theme.font.size['09']};
  font-weight: 900;
  color: ${({ theme }) => theme.colors.positive.on[0]};

  text-align: center;
  line-height: 130%;

  ${({ theme }) =>
    mobileMediaQuery(`
    font-size: ${theme.font.size['09']};
  `)};

  ${({ theme }) =>
    smallMobileMediaQuery(`
    font-size: ${theme.font.size['05']};
  `)};
`;

export const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  p {
    font-size: ${({ theme }) => theme.font.size['07']};
    font-weight: 900;
    line-height: 100%;
    font-style: italic;
    color: ${({ theme }) => theme.colors.positive.on[0]};

    ${({ theme }) =>
      mobileMediaQuery(`
      font-size: ${theme.font.size['06']};
    `)};

    ${({ theme }) =>
      smallMobileMediaQuery(`
      font-size: ${theme.font.size['04']};
    `)};
  }
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing[6]};

  strong {
    max-width: 480px;

    font-size: ${({ theme }) => theme.font.size['07']};
    font-weight: 900;
    color: ${({ theme }) => theme.colors.primary.onLow[0]};
    line-height: 130%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ theme }) =>
      mobileMediaQuery(`
      font-size: ${theme.font.size['05']};
    `)};

    ${({ theme }) =>
      smallMobileMediaQuery(`
      max-width: 280px;

      font-size: ${theme.font.size['04']};
    `)};
  }
`;

export const Footer = styled.div`
  max-width: 440px;

  font-size: ${({ theme }) => theme.font.size['05']};
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.secondary.on[0]};

  text-align: center;

  ${({ theme }) =>
    mobileMediaQuery(`
    max-width: 340px;
    font-size: ${theme.font.size['04']};
  `)};

  ${({ theme }) =>
    smallMobileMediaQuery(`
    max-width: 240px;
    font-size: ${theme.font.size['03']};
  `)};
`;

export const ImageRightContainer = styled.div`
  display: flex;

  img {
    max-height: 500px;
  }

  ${mobileMediaQuery(`
    height: fit-content;
  `)}

  ${smallMobileMediaQuery(`
    img {
      max-width: 100%;
    }
  `)};
`;
