import { useTranslation } from 'react-i18next';
import { Image, Text, View } from '@react-pdf/renderer';
import hugoWordsImg from '../../../../assets/images/hugoWordsImg.png';
import { pdfStyles } from './styles';

interface PdfWordsSlideProps {
  data: number | string;
}

export const PdfWordsSlide = ({ data }: PdfWordsSlideProps) => {
  const { t: translate } = useTranslation('WordsSlide');

  return (
    <View style={{ ...pdfStyles.slideBetween, backgroundColor: '#45389B' }}>
      <View style={pdfStyles.content}>
        <Text style={{ ...pdfStyles.title, color: '#CBD1FA' }}>
          {translate('WordsTitle')}
        </Text>
        <Text style={{ ...pdfStyles.subtitle, color: '#CBD1FA' }}>
          {translate('WordsFirstBody')}
        </Text>
        <Text style={{ ...pdfStyles.subtitle, color: '#CBD1FA' }}>
          {translate('WordsSecondBody')}
        </Text>
        <Text style={pdfStyles.metric}>{data}</Text>
        <Text style={{ ...pdfStyles.subtitle, color: '#CBD1FA' }}>
          {translate('WordsTertiaryBody')}
        </Text>
        <Text style={{ ...pdfStyles.finalContent, color: '#ffff' }}>
          {translate('WordsFourthBody')}
        </Text>
      </View>
      <Image src={hugoWordsImg} style={pdfStyles.bottomImage} />
    </View>
  );
};
