/** Libs */
import React from 'react';
import { DomainContainer } from '../styles';

interface INavbarProps {
  children: React.ReactNode;
}

const Domain: React.FC<INavbarProps> = ({ children }): JSX.Element => {
  return <DomainContainer>{children}</DomainContainer>;
};

export default Domain;
