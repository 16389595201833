import styled, { css } from 'styled-components';

type IOptionSelector = {
  checked?: boolean;
};

export const RadioGroup = styled.fieldset`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 32px;
  padding: 12px 0 8px;
`;

export const OptionInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const OptionSelector = styled.div<IOptionSelector>`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  cursor: pointer;
  border: 3px solid
    ${({ checked, theme }) =>
      checked ? theme.colors.primary.default[0] : theme.colors.surface.onSurfaceLow[0]};

  ${({ checked }) =>
    checked &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: ${({ theme }) => theme.borderRadius.full};
        background-color: ${({ theme }) => theme.colors.primary.default[0]};
      }
    `}
`;

export const OptionLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  height: 48px;
  cursor: pointer;

  font: ${({ theme }) => theme.typography.label.md};
`;
