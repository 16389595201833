export const fontControlAnddonsKeys = [
  'fontSize',
  'textStyle',
  'lineHeight',
  'letterSpacing',
  'highlightLetters',
];

export const navigationAddonsKeys = [
  'pageSpeech',
  'readerMode',
  'readingMask',
  'readingGuide',
  'highlightLinks',
  'pageStructure',
  'magnifier',
  'hideImages',
  'highlightHeadings',
  'pauseAnimations',
];

export const colorControlAddonsKeys = ['contrastMode', 'saturationMode', 'pageColors'];

export const addonsKeys = [
  ...fontControlAnddonsKeys,
  ...navigationAddonsKeys,
  ...colorControlAddonsKeys,
];

export const advancedKeys = ['isMajority', 'zIndex', 'doNotTrack'];
export const commonsConfigKeys = [
  'addonsEnabled',
  'side',
  'align',
  'avatar',
  'opacity',
  'highContrast',
  'colorButton',
];
