/** Libs */
import { YLink } from '@hand-talk/yotta-components';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  StyleLine,
  StyleCard,
  Section,
  Month,
  Row,
  Events,
  TitleCard,
  Text,
  Space,
  Title,
  SubTitle,
  Description,
  TextContainer,
} from './styles';

import { highlights } from './highlights';

export interface TimeLine {
  subtitle: string;
  text: string;
  month: string;
  direction: 'left' | 'right';
  double?: boolean;
  url?: string;
}

const Line: React.FC<{ double?: boolean }> = ({ double }) => (
  <StyleLine className="FlexColumn" double={double}>
    <div className="gray line"></div>
    <div className="gray circle"></div>
    <div className="gray line"></div>
  </StyleLine>
);

const Card: React.FC<TimeLine> = ({ month, subtitle, text, direction, double, url }) => {
  return (
    <StyleCard direction={direction}>
      <Space />
      <Row className="FlexColumn">
        <Month double={double}>{month}</Month>
        <Line double={double} />
      </Row>
      <Events noUrl={!url}>
        <TitleCard>{subtitle}</TitleCard>
        <Text>{text}</Text>
        {url && <YLink size="medium" href={url} children="Saiba Mais" />}
      </Events>
      <div className="gray LineMobile"></div>
    </StyleCard>
  );
};

const Timeline: React.FC = () => {
  const { t: translate } = useTranslation('Timeline');

  return (
    <Section>
      <TextContainer>
        <Title>{translate('Title')}</Title>
        <SubTitle>{translate('SubTitle')}</SubTitle>
        <Description>{translate('Description')}</Description>
      </TextContainer>
      {highlights.map((highlight) => (
        <div className="FlexColumn" key={highlight.month}>
          {highlight.events.map((event, index) => (
            <Card
              key={`${highlight.month}-${index}`}
              subtitle={translate(event.subtitle)}
              text={translate(event.text)}
              month={translate(highlight.month)}
              direction={event.direction}
              double={event.double}
              url={event.url}
            />
          ))}
        </div>
      ))}
    </Section>
  );
};

export default Timeline;
