// Libs
import { inject, injectable } from 'inversify';

// Domain
import AuthUser from '../entities/AuthUserEntity';
import UserRepository from '../../infra/repositories/UserRepository';

@injectable()
export default class DecodeAuthTokenUseCase {
  @inject(UserRepository) public userRepository: UserRepository;

  public execute(jwt: string) {
    if (!jwt) {
      throw new Error('Token não informado!');
    }

    const tokenData = this.userRepository.decodeToken(jwt);
    return AuthUser.fromObject(tokenData);
  }
}
