/** Libs */
import styled from 'styled-components';
import { YButton } from '@hand-talk/yotta-components';

/** Tokens */
import { feedback, highlight } from '../../assets/tokens/colors';
import { font } from '../../assets/tokens/fonts';
import { spacing } from '../../assets/tokens/spacings';

/** Services */
import {
  getIconStyle,
  mobileMediaQuery,
  printMediaQuery,
} from '../../../infra/services/helper';

export const ContainerTable = styled.div`
  width: 100%;
  min-height: 373px;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;

  .desktop {
    display: table-cell;
  }

  .mobile {
    display: none;
  }

  ${printMediaQuery(`
    max-height: 100%;
  `)}

  ${mobileMediaQuery(`
    .mobile {
      display: table-cell;
      padding: 0 !important;
    }

    .desktop {
      display: none;
    }
  `)}
`;

export const Thead = styled.thead`
  background: ${highlight.color.main};
  color: ${({ theme }) => theme.colors.secondary.on[0]};
  font: ${({ theme }) => theme.typography.label.mdBold};
`;

export const Th = styled.th`
  padding: 5px ${spacing.nano};
  white-space: nowrap;
  text-align: initial;

  ${mobileMediaQuery(`
    padding: 11px 8px;
    white-space: initial;
  `)}
`;

export const Tbody = styled.tbody`
  font: ${({ theme }) => theme.typography.label.smBold};
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};
  width: 100%;
`;

export const Td = styled.td`
  padding: ${spacing.nano};
  width: 100%;

  &:first-child {
    width: 150px;
  }
`;

export const TdLink = styled.td`
  padding: ${spacing.nano};
  font: ${font.underline.medium};
  font-family: ${font.family};
  text-decoration-line: underline;
  color: ${feedback.color.info.main};
  max-width: 100px !important;
  width: 100%;
  word-wrap: break-word;
`;

export const EmptyContainer = styled.p<{ modalExpansion?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  gap: 8px;
  padding-top: 9.5px;
  height: ${({ modalExpansion }) => (modalExpansion ? '383px' : 'auto')};
  color: ${({ theme }) => theme.colors.alert.default[0]};
  ${({ theme }) => getIconStyle('small', theme.colors.alert.default[0])}
`;

/** Modal */

export const ContainerForm = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[3]};
  padding-bottom: ${({ theme }) => theme.spacing[5]};
`;

export const ContainerInput = styled.div`
  margin: 0 ${({ theme }) => theme.spacing[3]};
  ${getIconStyle('36px', '#656565')};
`;

export const ExpansionButton = styled.span`
  display: flex;
  justify-content: center;
  padding-top: 9px;
`;

export const ContentExpansion = styled.div`
  margin: ${({ theme }) => theme.spacing[4]} 0 ${({ theme }) => theme.spacing[3]};
`;

/** Paginação */

export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: ${({ theme }) => theme.spacing[3]};
  gap: ${({ theme }) => theme.spacing[5]};
`;

export const ContainerNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 260px;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[3]};
`;

export const YButtonCustom = styled(YButton)`
  &:disabled {
    visibility: hidden;
  }
`;

export const PageNumber = styled.button<{ isActive: boolean }>`
  width: 42px;
  height: 48px;
  background-color: transparent;
  cursor: pointer;

  font: ${({ theme }) => theme.typography.label.md};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary.default[0] : theme.colors.surface.onSurface[0]};

  border-bottom: ${({ isActive, theme }) =>
    isActive ? `2px solid ${theme.colors.primary.default[0]}` : '2px solid transparent'};

  &:hover {
    background-color: ${({ isActive, theme }) =>
      isActive
        ? theme.colors.primary.container[0]
        : theme.colors.surface.bright.background[theme.states.pressing]};
    color: ${({ isActive, theme }) =>
      isActive
        ? theme.colors.primary.default[theme.states.hover]
        : theme.colors.surface.onSurface[theme.states.hover]};
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: ${({ theme }) => `2px solid ${theme.colors.primary.default[0]}`};
    outline-offset: ${({ theme }) => theme.spacing[2]};
  }
`;
