import React from 'react';

/** Hooks */
import useUserInfo from '../../hooks/useUserInfo';
import useAuth from '../../hooks/useAuth';
import useProtected from '../../hooks/useProtected';

/** Components */
import Footer from '../../components/Footer';
import DataSection from '../../components/DataSection';
import LoadingWrapper from '../LoadingWrapper';

const DomainList: React.FC = () => {
  const { authState } = useAuth();
  const { checkAuthentication } = useProtected();
  const { userInfoState, fetchUserInfo } = useUserInfo();

  checkAuthentication();

  React.useEffect(() => {
    if (authState.type === 'success') {
      fetchUserInfo();
    }
  }, [authState, fetchUserInfo]);

  const isLoading = userInfoState.type === 'loading' || authState.type === 'loading';

  let accessKeys: string[] = [];
  let domains: { domain: string; hasRetro: boolean }[] = [];

  if (userInfoState.type === 'success') {
    accessKeys = userInfoState.userInfo.reports.map((report) => report.accessKey.value);
    domains = userInfoState.userInfo.reports.map((report) => ({
      domain: report.info.domain,
      hasRetro: report.hasRetro,
    }));
  }

  return (
    <LoadingWrapper loading={isLoading}>
      <DataSection accessKeys={accessKeys} domains={domains} />
      <Footer />
    </LoadingWrapper>
  );
};

export default DomainList;
