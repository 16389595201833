import styled from 'styled-components';

export const YInputSelectedStyled = styled.div`
  div {
    gap: 0px;
  }
  button {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;
