import Navbar from './Navbar';
import Menu from './Menu';
import Title from './Title';
import Domain from './Containers/DomainContainer';
import Lower from './Containers/LowerContainer';
import Upper from './Containers/UpperContainer';

const Header = {
  Navbar,
  Menu,
  Title,
  Containers: {
    Domain,
    Upper,
    Lower,
  },
};

export default Header;
