// Libs
import styled from 'styled-components';

// Tokens
import { highlight } from '../../../assets/tokens/colors';

export const TableData = styled.td`
  font: ${({ theme }) => theme.typography.label.smBold};
  color: ${highlight.color.medium};
  text-align: center;
  padding: 15px 0;
  user-select: none;
`;
