// Libs
import { useCallback, useState } from 'react';
import { useInjection } from 'inversify-react';

// Models
import UserInfoModel from '../../domain/entities/UserInfoEntity';
import UserInfoRepository from '../../infra/repositories/UserInfoRepository';

interface IUserInfoResult {
  type: 'loading' | 'none';
}

interface IUserInfoSuccess {
  type: 'success';
  userInfo: UserInfoModel;
}

interface IUserInfoError {
  type: 'error';
  message: string;
}

export type UserInfoState = IUserInfoResult | IUserInfoSuccess | IUserInfoError;

export default function useUserInfo() {
  const userInfoRepository = useInjection(UserInfoRepository);
  const [userInfoState, setUserInfoState] = useState<UserInfoState>({
    type: 'none',
  });

  const fetchUserInfo = useCallback(async () => {
    try {
      setUserInfoState({ type: 'loading' });
      const userInfo = await userInfoRepository.get();
      setUserInfoState({ type: 'success', userInfo });
    } catch (error) {
      const { message } = error as Error;
      setUserInfoState({ type: 'error', message });
    }
  }, [userInfoRepository]);

  return { fetchUserInfo, userInfoState };
}
