import { useTranslation } from 'react-i18next';
import { Image, Text, View } from '@react-pdf/renderer';
import calendarImg from '../../../../assets/images/calendar.png';
import { pdfStyles } from './styles';

export const PdfWelcomeSlide = () => {
  const { t: translate } = useTranslation('WelcomeSlide');

  const paragraphs = [
    { content: 'WelcomeFirstBody', highlighted: 'WelcomeFirstHighlighted' },
    { content: 'WelcomeSecondBody', highlighted: 'WelcomeSecondHighlighted' },
    { content: 'WelcomeTertiaryBody', highlighted: 'WelcomeTertiaryHighlighted' },
  ];

  return (
    <View style={pdfStyles.welcomeSlide}>
      <View style={pdfStyles.welcomeContent}>
        <Text style={pdfStyles.welcomeSlideTitle}>{translate('WelcomeTitle')}</Text>
        <Text style={pdfStyles.welcomeSlideSubtitle}>{translate('WelcomeSubTitle')}</Text>
        <View>
          {paragraphs.map(({ content, highlighted }) => {
            const [initial, final] = translate(highlighted)
              ? translate(content).split(translate(highlighted))
              : [translate(content)];

            return (
              <Text key={content} style={pdfStyles.welcomeSlideParagraph}>
                {initial}
                {highlighted && (
                  <Text style={pdfStyles.welcomeSlideStrong}>
                    {translate(highlighted)}
                  </Text>
                )}
                {final && final}
              </Text>
            );
          })}
        </View>
      </View>
      <Image src={calendarImg} style={pdfStyles.welcomeImage} />
    </View>
  );
};
