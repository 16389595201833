import styled from 'styled-components';
import {
  mobileMediaQuery,
  smallMobileMediaQuery,
} from '../../../../../infra/services/helper';

export const Container = styled.div`
  min-height: calc(100vh - 60px);

  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing[6]};
  padding-top: ${({ theme }) => theme.spacing[12]};
  padding-bottom: 0;

  ${({ theme }) =>
    mobileMediaQuery(`
    padding-top: ${theme.spacing[6]};

    padding-left: 0;
    padding-right: 0;
  `)};
`;

export const Content = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[7]};
  flex-direction: column;
  align-items: center;

  text-align: center;
`;

export const Header = styled.h3`
  max-width: 600px;

  font: ${({ theme }) => theme.typography.display.lg};
  font-size: ${({ theme }) => theme.font.size['09']};
  font-weight: 900;
  color: ${({ theme }) => theme.colors.primary.onContainer[0]};

  text-align: center;
  line-height: 130%;

  ${({ theme }) =>
    mobileMediaQuery(`
    max-width: 400px;

    font-size: ${theme.font.size['06']};
  `)};

  ${({ theme }) =>
    smallMobileMediaQuery(`
    max-width: 300px;

    font-size: ${theme.font.size['04']};
  `)};
`;

export const Paragraphs = styled.div`
  max-width: 700px;

  p {
    font-size: ${({ theme }) => theme.font.size['05']};
    font-weight: 900;
    line-height: 150%;
    font-style: italic;
    color: ${({ theme }) => theme.colors.primary.onContainer[0]};

    ${({ theme }) =>
      mobileMediaQuery(`
       max-width: 400px;

      font-size: ${theme.font.size['04']};
    `)};

    ${({ theme }) =>
      smallMobileMediaQuery(`
      max-width: 250px;

      font-size: ${theme.font.size['03']};
    `)};
  }

  p:last-child {
    font-weight: 400;
    font-style: normal;
  }
`;

export const ImageContainer = styled.div`
  display: flex;

  img {
    width: 400px;
  }
`;
