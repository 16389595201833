import { injectable } from 'inversify';
import Entity from './entity';

/** Schema */
import { RemoteConfigSchema } from '../schemas/remoteConfig/RemoteConfigSchema';

@injectable()
export class RemoteConfigEntity extends Entity<RemoteConfigSchema> {
  public get isActive() {
    return this._schema.isActive;
  }

  public set sid(newSid: string) {
    this._schema.sid = newSid;
  }

  public get sid() {
    return this._schema.sid;
  }

  public get config() {
    return this._schema.config;
  }
}
