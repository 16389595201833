/** Interfaces */
import { ITopContainer } from '../../../infra/services/interfaces';

/** Components */
import Menu from '../Menu';
import SmallDataCard from '../SmallDataCard';
import CustomContainer from '../CustomContainer';
import { YInputSelect, YMenu, YMenuItem } from '@hand-talk/yotta-components';
import { YInputSelectedStyled } from '../../components/YInputSelect';

/** Styles */
import {
  Container,
  ContainerLeftSide,
  ContainerRightSide,
  DomainSelectContainer,
  Wrapper,
} from './styles';

/** Services */
import * as Analytics from '../../../infra/services/analytics';
import { useTranslation } from 'react-i18next';
import { formatDashboardValue } from '../../../infra/services/helper';

const renderMenu = ({ options, onSelect, selectedOption, onClose }: any) => (
  <YMenu
    fluid={true}
    direction="column"
    position={{ top: '53px' }}
    zIndex={2}
    onClose={onClose}
  >
    {options.map((option: any) => (
      <YMenuItem
        key={option.value}
        onClick={() => onSelect(option)}
        selected={option.value === selectedOption?.value}
      >
        {option.name}
      </YMenuItem>
    ))}
  </YMenu>
);

const TopContainer: React.FC<ITopContainer> = ({
  filter,
  onClickExportCsv,
  onClickExportPdf,
  domain,
  report,
  userInfoState,
}) => {
  const { t: translateReport } = useTranslation('Report');
  const { t: translateTopContainer } = useTranslation('TopContainer');

  const currentDomain =
    userInfoState.type === 'success'
      ? (userInfoState.userInfo?.reports || []).find(
          (reportRow) => reportRow.info.domain === report.info.domain
        )
      : null;

  const translatedWordsAccumulated = report?.achievements.translatedWordsAccumulated ?? 0;
  const translationsAccumulatedFormatted = formatDashboardValue(
    translatedWordsAccumulated
  ).formatted;

  const handleChangeReport = async (option: { name: string; value: string }) => {
    await Analytics.clickDomainInDropdownsEvent(option.name);
    window.location.href = `/${option.value}`;
  };

  return (
    <Wrapper className="NotPrint">
      <CustomContainer>
        <div className="OnlyPrint">
          {translateTopContainer('DomainLabel')}: {domain}
        </div>
        <Container>
          <ContainerLeftSide>
            {report.info && userInfoState.type === 'success' && (
              <DomainSelectContainer>
                <h6>{translateTopContainer('DomainLabel')}: </h6>
                <YInputSelectedStyled>
                  <YInputSelect
                    onSelectOption={handleChangeReport}
                    placeholder={report?.info.domain}
                    value={currentDomain?.accessKey?.value}
                    id="domainSelect"
                    renderMenu={renderMenu}
                    options={userInfoState.userInfo.reports.map((partialReport) => ({
                      name: partialReport.info.domain,
                      value: partialReport.accessKey.value,
                    }))}
                  />
                </YInputSelectedStyled>
              </DomainSelectContainer>
            )}
            <SmallDataCard
              text={translateReport('translatedWords')}
              value={translationsAccumulatedFormatted}
            />
          </ContainerLeftSide>
          <ContainerRightSide>
            {filter}
            <Menu.Export
              onClickExportCsv={onClickExportCsv}
              onClickExportPdf={onClickExportPdf}
            />
          </ContainerRightSide>
        </Container>
      </CustomContainer>
    </Wrapper>
  );
};

export default TopContainer;
