import styled from 'styled-components';
import { YButton, YMenu } from '@hand-talk/yotta-components';

export const MenuContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const MenuOverride = styled(YMenu)`
  width: 225px;
  max-width: none;
`;
export const ButtonOverride = styled(YButton)`
  @media (max-width: 480px) {
    width: 100% !important;
  }
`;
