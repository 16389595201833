// Libs
import React from 'react';
import { YButton, YIcon, YLink } from '@hand-talk/yotta-components';
import { useTranslation } from 'react-i18next';

// Styles
import { Container, ContainerButton, StyledListItem } from './styles';

// Components
import CustomContainer from '../CustomContainer';
import Title from '../Title';

// Services
import { formatDomain } from '../../../infra/services/helper';
import * as Analytics from '../../../infra/services/analytics';

type DataSectionProps = {
  accessKeys: string[];
  domains: { domain: string; hasRetro: boolean }[];
};

const DataSection: React.FC<DataSectionProps> = ({ accessKeys, domains }) => {
  const { t: translationsDataSection } = useTranslation('DataSection');

  const handleDomainLinkClick = async (domain: string) => {
    await Analytics.clickOnDomainLinksEvent(domain);
  };

  const handleDashboardAccess = async (
    key: string,
    domain: string,
    retrospective = false
  ) => {
    await Analytics.dashboardAccessEvent(domain);
    const path = retrospective ? `/retrospective/${key}` : `/${key}`;
    window.location.href = path;
  };

  return (
    <CustomContainer>
      <Container>
        <Title
          tag={'h2'}
          className={'TitleDomainList'}
          children={translationsDataSection('titleDataSection')}
        />
        <ul>
          {accessKeys.map((key, index) => (
            <StyledListItem key={index}>
              <YLink
                href={formatDomain(domains[index].domain)}
                size="big"
                target="_blank"
                onClick={() => handleDomainLinkClick(domains[index].domain)}
              >
                {domains[index].domain}
              </YLink>
              <ContainerButton>
                <YButton
                  icon={<YIcon name="arrowRight" />}
                  iconPosition="right"
                  size="fitContent"
                  title={translationsDataSection('accessRetrospective')}
                  aria-label={translationsDataSection('accessRetrospective')}
                  variation="tonal"
                  disabled={!domains[index].hasRetro}
                  onClick={() => handleDashboardAccess(key, domains[index].domain, true)}
                >
                  {translationsDataSection('retrospective')}
                </YButton>
                <YButton
                  icon={<YIcon name="arrowRight" />}
                  iconPosition="right"
                  size="fitContent"
                  variation="text"
                  onClick={() => handleDashboardAccess(key, domains[index].domain)}
                >
                  {translationsDataSection('accessText')}
                </YButton>
              </ContainerButton>
            </StyledListItem>
          ))}
        </ul>
      </Container>
    </CustomContainer>
  );
};

export default DataSection;
