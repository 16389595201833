// Components
import InvalidToken from '../../components/InvalidToken';
import Footer from '../../components/Footer';

const NotFound = () => (
  <>
    <InvalidToken />
    <Footer />
  </>
);

export default NotFound;
