export type EventDetails = {
  subtitle: string;
  text: string;
  direction: 'left' | 'right';
  double?: boolean;
  url?: string;
};

export type Events = {
  month: string;
  events: EventDetails[];
};

export const highlights: Events[] = [
  {
    month: 'JanMonth',
    events: [
      {
        subtitle: 'JanFeatureTitle',
        text: 'JanFeatureDescription',
        direction: 'left',
      },
    ],
  },
  {
    month: 'FebMonth',
    events: [
      {
        subtitle: 'FebFeatureTitle',
        text: 'FebFeatureDescription',
        direction: 'right',
      },
    ],
  },
  {
    month: 'MarMonth',
    events: [
      {
        subtitle: 'MarFeatureTitle',
        text: 'MarFeatureDescription',
        direction: 'left',
      },
    ],
  },
  {
    month: 'AugMonth',
    events: [
      {
        subtitle: 'AugFeatureTitle',
        text: 'AugFeatureDescription',
        direction: 'right',
      },
    ],
  },
  {
    month: 'SepMonth',
    events: [
      {
        subtitle: 'SepFeatureTitle',
        text: 'SepFeatureDescription',
        direction: 'left',
      },
    ],
  },
  {
    month: 'OctMonth',
    events: [
      {
        subtitle: 'OctFeatureTitle',
        text: 'OctFeatureDescription',
        direction: 'right',
      },
    ],
  },
  {
    month: 'NovMonth',
    events: [
      {
        subtitle: 'NovFeatureTitle1',
        text: 'NovFeatureDescription1',
        direction: 'left',
      },
      {
        subtitle: 'NovFeatureTitle2',
        text: 'NovFeatureDescription2',
        direction: 'right',
        double: true,
      },
      {
        subtitle: 'NovFeatureTitle3',
        text: 'NovFeatureDescription3',
        direction: 'left',
        double: true,
      },
    ],
  },
  {
    month: 'DecMonth',
    events: [
      {
        subtitle: 'DecFeatureTitle',
        text: 'DecFeatureDescription',
        direction: 'right',
      },
    ],
  },
];
