/** Libs */
import { useTranslation } from 'react-i18next';

/** Services */
import {
  formatDateWithCustomTemplate,
  formatPeriodToDate,
  getTemplate,
  periodDay,
} from '../../../infra/services/helper';

import { ITranslationHistory, Language } from '../../../infra/services/interfaces';

const GetDataDaily = (
  data: ITranslationHistory,
  selectedLanguage: Language,
  language: string
) => {
  const dailyInfo = data.signLanguage[selectedLanguage]?.dailyInfo || {};

  const { t } = useTranslation('Chart');

  return Object.entries(dailyInfo).map(([key, value]) => {
    const template = getTemplate(language);
    const day = formatDateWithCustomTemplate(formatPeriodToDate(key), template);
    const dayNumber = periodDay(key);

    return {
      day,
      dayNumber,
      [t('translatedWordsTitle')]: value,
    };
  });
};

export default GetDataDaily;
