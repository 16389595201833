import styled from 'styled-components';
import { mobileMediaQuery } from '../../../infra/services/helper';

export const SmallDataCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: fit-content;
  padding: 2px 12px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  ${mobileMediaQuery(`
    width: 100%;
  `)}
`;

export const Text = styled.p`
  font: ${({ theme }) => theme.typography.label.sm};
  color: ${({ theme }) => theme.colors.surface.onSurfaceLow[0]};
`;

export const Value = styled.p`
  font: ${({ theme }) => theme.typography.title.md};
  color: ${({ theme }) => theme.colors.secondary.default[0]};
`;
