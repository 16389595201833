import { isMobile } from '../../../../../infra/services/helper';
import Resources from '../../../../assets/svg/Resources';
import topAddonsMobileMaya from '../../../../assets/svg/topAddonsMobileMaya.svg';
import mayaHandOnHip from '../../../../assets/svg/mayaHandOnHip.svg';
import {
  Container,
  Content,
  Footer,
  Header,
  ImageLeftContainer,
  ImageRightContainer,
  MetricsContainer,
  Paragraphs,
} from './styles';
import { useTranslation } from 'react-i18next';

interface TopAddonsSlideProps {
  isPrintActive?: boolean;
  data: { value: string }[];
}

export const TopAddonsSlide = ({ isPrintActive, data }: TopAddonsSlideProps) => {
  const { t: translate } = useTranslation('TopAddonsSlide');

  return (
    <Container isPrintActive={isPrintActive}>
      {!isPrintActive && (
        <ImageLeftContainer>
          <Resources alt={translate('ResourcesAlt')} />
        </ImageLeftContainer>
      )}
      <Content>
        <Header>{translate('AddonsTitle')}</Header>
        <Paragraphs>
          <p>{translate('AddonsFirstBody')}</p>
          <MetricsContainer>
            {data.map((row) => (
              <strong key={row.value}>{row.value}</strong>
            ))}
          </MetricsContainer>
        </Paragraphs>
        <Footer>{translate('AddonsSecondBody')}</Footer>
      </Content>
      <ImageRightContainer>
        {isMobile() || isPrintActive ? (
          <img src={topAddonsMobileMaya} alt={translate('TopAddonsMobileMaya')} />
        ) : (
          <img src={mayaHandOnHip} alt={translate('TopAddonsMobileMaya')} />
        )}
      </ImageRightContainer>
    </Container>
  );
};
