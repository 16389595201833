/** Libs */
import React from 'react';

/** Components */
import CustomContainer from '../CustomContainer';
import LogoHT from '../../assets/svg/LogoHT';

/** Styles */
import { NavContainer, ButtonsContainer, LogoWrapper } from './styles';
import { useTranslation } from 'react-i18next';

interface INavbarProps {
  children?: React.ReactNode;
}

const Navbar: React.FC<INavbarProps> = ({ children }): JSX.Element => {
  const { t: translate } = useTranslation('Header');

  return (
    <>
      <NavContainer>
        <CustomContainer className="SpaceBetween">
          <LogoWrapper>
            <a
              href="/"
              title={translate('backToHome')}
              aria-label={translate('backToHome')}
            >
              <LogoHT />
            </a>
          </LogoWrapper>
          <ButtonsContainer className="NotPrint">{children}</ButtonsContainer>
        </CustomContainer>
      </NavContainer>
      <div className="RainbowLoader"></div>
    </>
  );
};

export default Navbar;
