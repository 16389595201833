/** Libs */
import styled from 'styled-components';

/** Services */
import { mobileMediaQuery, tabletMediaQuery } from '../../../infra/services/helper';

export const Wrapper = styled.div`
  margin-top: 24px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${({ theme }) => theme.colors.border.low[0]};

  ${tabletMediaQuery(`
    flex-direction: column;
    align-items: flex-start;
  `)}

  ${mobileMediaQuery(`
    flex-direction: column;
    padding-bottom: 16px;
    align-items: center;
  `)}

  > section {
    display: flex;
    align-items: center;
    gap: 8px;

    ${mobileMediaQuery(`
      flex-direction: column;
    `)}
  }
`;

export const ContainerLeftSide = styled.section`
  ${tabletMediaQuery(`
    flex-direction: column;
  `)}
`;

export const ContainerRightSide = styled.section`
  gap: 4px;

  ${tabletMediaQuery(`
    width: 100%;
  `)}

  ${mobileMediaQuery(`
    width: fit-content;
  `)}
`;

export const DomainSelectContainer = styled.div`
  display: flex;
  align-items: center;
`;
