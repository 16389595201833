/** Libs */
import React from 'react';

/** Services */
import usePreferencies from '../../../hooks/usePreferencies';

/** Styles */
import { TableData } from './styles';

const date = new Date(1970, 0, 4);

const locales: Record<string, string> = {
  pt: 'pt-br',
  en: 'en-us',
};

export const CalendarWeekDays: React.FC = () => {
  const { oralLanguage } = usePreferencies();
  const locale = locales[oralLanguage];

  const days = Array.from({ length: 7 }, () => {
    date.setDate(date.getDate() + 1);
    return date.toLocaleDateString(locale, { weekday: 'long' }).toLowerCase();
  });

  return (
    <tr>
      {days.map((day) => (
        <TableData key={day} abbr={day}>
          {day.toUpperCase()[0]}
        </TableData>
      ))}
    </tr>
  );
};
