/** Libs */
import React from 'react';

/** Interfaces */
import { IReportInsight, IPeriod, SourceType } from '../../../infra/services/interfaces';

/** Use cases */
import useFilteredInsightReportUseCase from '../use-cases/useFilteredInsightReportUseCase';

interface IFilteredInsightsLoading {
  type: 'loading';
}

interface IFilteredInsightsSuccess {
  type: 'success';
  report: IReportInsight;
}

interface IFilteredInsightsError {
  type: 'error';
  message: string;
}

type FilteredInsightsState =
  | IFilteredInsightsLoading
  | IFilteredInsightsSuccess
  | IFilteredInsightsError;

export default function useFilteredInsightViewModel() {
  const filteredInsightReportUseCase = useFilteredInsightReportUseCase();
  const [state, setState] = React.useState<Record<string, FilteredInsightsState>>({});

  const filteredInsight = React.useCallback(
    async (
      token: string,
      period: IPeriod,
      source: SourceType,
      limit: number = 10,
      offset: number = 0,
      filterContent?: string
    ) => {
      setState((prevState) => ({
        ...prevState,
        [source]: { type: 'loading' },
      }));
      try {
        const report = await filteredInsightReportUseCase.execute(
          token,
          period,
          source,
          limit,
          offset,
          filterContent
        );
        setState((prevState) => ({
          ...prevState,
          [source]: { type: 'success', report: report },
        }));
      } catch (error) {
        const { message } = error as Error;
        setState((prevState) => ({
          ...prevState,
          [source]: { type: 'error', message },
        }));
      }
    },
    [filteredInsightReportUseCase]
  );

  return { filteredInsight, state };
}
