import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSVG = styled.svg<{ strokeColor?: string; big?: boolean }>`
  width: ${({ big }) => (big ? '66.67px' : '24px')};
  height: ${({ big }) => (big ? '66.67px' : '24px')};
  padding: ${({ big }) => (big ? '13.33px' : '0')};
  box-sizing: content-box;
  fill: ${({ strokeColor, theme }) => strokeColor || theme.colors.primary.default[0]};
  animation: ${rotateAnimation} 2s linear infinite;
`;

interface LoadingProps {
  strokeColor?: string;
  big?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ strokeColor, big }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeColor={strokeColor}
      big={big}
      aria-label="Ícone de carregamento"
    >
      <path d="M21 12C21.5523 12 22.0052 11.5512 21.95 11.0017C21.8425 9.9303 21.5625 8.88109 21.1187 7.89526C20.5378 6.60478 19.6896 5.4523 18.6302 4.514C17.5708 3.5757 16.3243 2.87294 14.9731 2.45219C13.9409 2.13077 12.8655 1.97951 11.789 2.00222C11.2369 2.01388 10.846 2.51767 10.9128 3.06591C10.9795 3.61415 11.4789 3.9979 12.0312 4.00006C12.8254 4.00316 13.6166 4.12452 14.3785 4.36175C15.4594 4.69835 16.4566 5.26056 17.3042 6.0112C18.1517 6.76184 18.8302 7.68383 19.295 8.71621C19.6225 9.44381 19.8385 10.2146 19.9376 11.0026C20.0064 11.5506 20.4477 12 21 12Z" />
    </StyledSVG>
  );
};

export default Loading;
