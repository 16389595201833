//libs
import { inject, injectable } from 'inversify';

// Apis
import DashboardApi from '../apis/DashboardApi';

// Types
import {
  IReportInsight,
  IPeriod,
  IReportSchema,
  Language,
  Report,
  SourceType,
} from '../services/interfaces';

@injectable()
export default class ReportRepository {
  @inject(DashboardApi) dashboardApi: DashboardApi;

  public getMonthly = async (accessKey: string, language: Language) => {
    if (!accessKey) throw new Error('Chave de acesso não informada.');

    const report = await this.dashboardApi.get<IReportSchema>('getReport', {
      accessKey,
      language,
    });

    return report;
  };

  public getYearly = async (accessKey: string, language: Language) => {
    if (!accessKey) throw new Error('Chave de acesso não informada.');

    const report = await this.dashboardApi.get<IReportSchema>('getYearlyReport', {
      accessKey,
      language,
    });

    return report;
  };

  public filter = async (accessKey: string, period: IPeriod, language: Language) => {
    if (!accessKey) throw new Error('Chave de acesso não informada.');

    const report = await this.dashboardApi.get<Report>('filterReport', {
      ...period,
      accessKey,
      language,
    });

    return report;
  };

  public async filteredInsight(
    token: string,
    period: IPeriod,
    source: SourceType,
    limit: number = 10,
    offset: number = 0,
    filterContent?: string
  ): Promise<IReportInsight> {
    if (!token) throw new Error('Token não informado ou inválido.');

    const reportInsight = await this.dashboardApi.get<IReportInsight>(
      'filteredInsightReport',
      {
        token,
        startDate: period.startDate,
        endDate: period.endDate,
        source,
        limit,
        offset,
        filterContent,
      }
    );

    return reportInsight;
  }
}
