// Libs
import React from 'react';
import { useInjection } from 'inversify-react';

// Interfaces
import {
  IPeriod,
  IReportSchema,
  Language,
  Report,
} from '../../infra/services/interfaces';
import { IFilterOptions } from '../components/Filter/types';

// Constants
import {
  getDefaultFilterPeriod,
  getAverageFilterPeriod,
} from '../../infra/services/utils';

// Repositories
import ReportRepository from '../../infra/repositories/ReportRepository';

interface IState {
  report?: Report;
  reportYearly?: IReportSchema;
  isLoading?: boolean;
  isLoadingtYearly?: boolean;
  isFilter?: boolean;
  filterOptions?: IFilterOptions;
}

/**
 * Hook para obter os dados do relatório
 */
export default function useReport() {
  const reportRepository = useInjection(ReportRepository);
  const [state, setState] = React.useState<IState>({});

  const extractFilterOptions = (report: IReportSchema): IFilterOptions => {
    return {
      defaultAvailablePeriod: getDefaultFilterPeriod(report.info),
      averageAvailablePeriod: getAverageFilterPeriod(report.info),
    };
  };

  const handleError = (error: unknown) => {
    console.error(error);
    if (window.location.pathname !== '/not-found') {
      window.location.href = '/not-found';
    }
  };

  /**
   * Obtém um relatório utilizando a chave de acesso
   */
  const getReport = React.useCallback(async (accessKey: string, language: Language) => {
    setState({ isLoading: true });
    try {
      if (!accessKey) throw new Error('Chave de acesso não informada.');
      const report = await reportRepository.getMonthly(accessKey, language);
      setState({
        isLoading: false,
        report,
        filterOptions: extractFilterOptions(report),
      });
    } catch (error) {
      handleError(error);
    }
  }, []);

  const getYearlyReport = React.useCallback(
    async (accessKey: string, language: Language) => {
      setState({ isLoading: true });

      try {
        if (!accessKey) throw new Error('Chave de acesso não informada.');

        const normalizedAccessKey = accessKey.startsWith('retrospective/')
          ? accessKey.replace('retrospective/', '')
          : accessKey;

        const reportYearly = await reportRepository.getYearly(
          normalizedAccessKey,
          language
        );

        setState({
          isLoading: false,
          reportYearly,
        });
      } catch (error) {
        handleError(error);
      }
    },
    []
  );

  /**
   * Obtém um relatório utilizando a chave de acesso
   */
  const filterReport = React.useCallback(
    async (accessKey: string, period: IPeriod, language: Language) => {
      setState((prev) => ({
        isLoading: true,
        isFilter: true,
        filterOptions: prev.filterOptions,
      }));
      try {
        const report = await reportRepository.filter(accessKey, period, language);
        setState((prev) => ({
          isLoading: false,
          isFilter: true,
          report,
          filterOptions: prev.filterOptions,
        }));
      } catch (error) {
        handleError(error);
      }
    },
    []
  );

  return { ...state, getReport, filterReport, getYearlyReport };
}
