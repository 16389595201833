type PrimitiveTypes = string | number | boolean;

export const isValidNumber = (value: string | number | boolean) => {
  return !Number.isNaN(Number(value));
};

export const isValidBoolean = (value: PrimitiveTypes) => {
  return typeof value === 'number'
    ? false
    : ['true', 'false', true, false].includes(value as string | boolean);
};

export const parseToOriginalPrimitiveType = (v: PrimitiveTypes): PrimitiveTypes => {
  if (isValidBoolean(v)) return v === 'true' || v === true;
  else if (isValidNumber(v)) return Number(v);

  return v;
};
