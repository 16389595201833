import React from 'react';

interface UseAccessibleFocusModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const useAccessibleFocusModal = ({ isOpen, onClose }: UseAccessibleFocusModalProps) => {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<HTMLHeadingElement>(null);

  React.useEffect(() => {
    if (isOpen) {
      /**
       * O foco inicia no título após a abertura e recarregamento do modal
       */
      titleRef.current?.focus();

      /** Fecha o modal ao pressionar a tecla ESC */
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };

      /** Segura o foco dentro do modal  */
      const handleTabKey = (event: KeyboardEvent) => {
        if (event.key === 'Tab' && modalRef.current) {
          /**  Seleciona todos os elementos focáveis dentro do modal */
          const focusableElements = modalRef.current.querySelectorAll<HTMLElement>(
            'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
          );
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];

          if (event.shiftKey) {
            if (document.activeElement === firstElement) {
              event.preventDefault();
              lastElement.focus();
            }
            /** Se tab for pressionado no último elemento, move o foco para o primeiro */
          } else {
            if (document.activeElement === lastElement) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keydown', handleTabKey);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('keydown', handleTabKey);
      };
    }
  }, [isOpen, onClose]);

  return { modalRef, titleRef };
};

export default useAccessibleFocusModal;
