// Types
import { Timestamp } from '../../../../infra/services/Timestamp';
import {
  isSameMonth,
  format,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  eachWeekOfInterval,
  addWeeks,
} from 'date-fns';
import { CalendarDayData } from '../Calendar/interfaces';

export type CalendarWeek = CalendarDayData[];
export type Calendar = CalendarWeek[];

type DateConstructValue = string | Timestamp | Date;

export const makeCalendar = (dateConstructValue: DateConstructValue): Calendar => {
  const selectedMonth = new Date(dateConstructValue);
  const startOfTheSelectedMonth = startOfMonth(selectedMonth);
  const start = startOfWeek(startOfTheSelectedMonth);
  const end = addWeeks(start, 5);

  const calendar = eachWeekOfInterval({ start, end }).map<CalendarWeek>((week) => {
    const weekDays = eachDayOfInterval({
      start: week,
      end: endOfWeek(week),
    }).map<CalendarDayData>((day) => ({
      isSameMonth: isSameMonth(day, selectedMonth),
      timestamp: day.getTime() as Timestamp,
      displayDay: format(day, 'dd'),
    }));

    return weekDays;
  });

  return calendar;
};
