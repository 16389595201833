import Conquest from '../../../../assets/svg/Conquest';
import hugoShowingPhone from '../../../../assets/svg/hugoShowingPhone.svg';
import topPagesMobileHugo from '../../../../assets/svg/topPagesMobileHugo.svg';
import { isMobile } from '../../../../../infra/services/helper';
import {
  Container,
  Content,
  Footer,
  Header,
  ImageLeftContainer,
  ImageRightContainer,
  MetricsContainer,
  Paragraphs,
} from './styles';
import { useTranslation } from 'react-i18next';

interface PagesSlide {
  isPrintActive?: boolean;
  data: { value: string }[];
}

export const PagesSlide = ({ isPrintActive, data }: PagesSlide) => {
  const { t: translate } = useTranslation('PagesSlide');

  return (
    <Container isPrintActive={isPrintActive}>
      {!isPrintActive && (
        <ImageLeftContainer>
          <img src={hugoShowingPhone} alt={translate('HugoShowingPhoneAlt')} />
        </ImageLeftContainer>
      )}
      <Content>
        <Header>{translate('PagesTitle')}</Header>
        <Paragraphs>
          <p>{translate('PagesFirstBody')}</p>
          <MetricsContainer>
            {data.map((row) => (
              <strong key={row.value}>{row.value}</strong>
            ))}
          </MetricsContainer>
        </Paragraphs>
        <Footer>{translate('PagesTertiaryBody')}</Footer>
      </Content>
      <ImageRightContainer>
        {isMobile() || isPrintActive ? (
          <img src={topPagesMobileHugo} alt={translate('HugoShowingPhoneAlt')} />
        ) : (
          <Conquest alt={translate('ConquestAlt')} />
        )}
      </ImageRightContainer>
    </Container>
  );
};
