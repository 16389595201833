const Resources: React.FC<{ alt: string }> = ({ alt }) => {
  return (
    <svg
      viewBox="0 0 215 587"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '100%', height: '100%' }}
      role="img"
      aria-label={alt}
    >
      <path
        d="M3.06681 43.4023C1.92282 37.4846 5.59479 31.8171 11.291 30.709L169.152 -0.000350573L213.958 231.779L45.8066 264.49L3.07445 43.4418L3.06681 43.4023Z"
        fill="#DEDEDE"
      />
      <path
        d="M78.2452 63.9268L28.5393 73.5964C20.9476 75.0733 16.0293 82.6643 17.554 90.5514L28.5738 147.556C30.0985 155.444 37.4888 160.64 45.0805 159.163L94.7864 149.494C102.378 148.017 107.296 140.426 105.772 132.539L94.7519 75.5335C93.2272 67.6464 85.8369 62.4499 78.2452 63.9268Z"
        fill="white"
      />
      <path
        d="M158.885 48.2626L109.17 57.9415C101.576 59.4198 96.6557 67.0108 98.179 74.8966L109.189 131.892C110.712 139.778 118.102 144.972 125.695 143.494L175.411 133.815C183.004 132.337 187.925 124.746 186.402 116.86L175.392 59.8644C173.869 51.9786 166.479 46.7843 158.885 48.2626Z"
        fill="white"
      />
      <path
        d="M175.662 134.912L125.908 144.599C117.743 146.188 109.763 140.58 108.125 132.099L97.1077 75.0645C95.4696 66.5842 100.783 58.3877 108.948 56.798L158.702 47.1116C166.868 45.5219 174.847 51.1306 176.486 59.6109L187.503 116.646C189.141 125.126 183.828 133.323 175.662 134.912ZM109.345 59.0537C102.356 60.4142 97.8326 67.3929 99.2346 74.6505L110.252 131.685C111.654 138.943 118.448 143.718 125.436 142.358L175.19 132.671C182.178 131.311 186.694 124.293 185.3 117.075L174.283 60.0397C172.881 52.7821 166.087 48.0068 159.098 49.3673L109.345 59.0537Z"
        fill="#F08413"
      />
      <path
        d="M176.126 137.401L126.42 147.071C118.828 148.548 113.91 156.139 115.435 164.026L126.454 221.031C127.979 228.918 135.369 234.115 142.961 232.638L192.667 222.968C200.259 221.491 205.177 213.9 203.652 206.013L192.633 149.008C191.108 141.121 183.718 135.924 176.126 137.401Z"
        fill="white"
      />
      <path
        d="M156.473 215.241C157.878 214.968 159.284 215.922 159.573 217.421C159.855 218.88 158.952 220.325 157.509 220.606C156.104 220.879 154.698 219.925 154.408 218.426C154.126 216.967 155.029 215.522 156.473 215.241Z"
        fill="#AEAEAE"
      />
      <path
        d="M165.052 213.574C166.457 213.301 167.863 214.255 168.152 215.754C168.434 217.213 167.531 218.658 166.088 218.939C164.683 219.212 163.277 218.258 162.987 216.759C162.705 215.3 163.608 213.855 165.052 213.574Z"
        fill="#AEAEAE"
      />
      <path
        d="M173.631 211.907C175.036 211.634 176.442 212.588 176.732 214.087C177.014 215.546 176.11 216.991 174.667 217.272C173.262 217.545 171.856 216.591 171.567 215.092C171.285 213.633 172.188 212.188 173.631 211.907Z"
        fill="#AEAEAE"
      />
      <path
        d="M148.539 13.2541C156.134 11.7753 163.521 16.9671 165.045 24.8556C166.569 32.7441 161.65 40.3315 154.055 41.8103C146.459 43.2891 139.072 38.0973 137.548 30.2088C136.025 22.3204 140.943 14.7328 148.539 13.2541Z"
        fill="#333333"
      />
      <path
        d="M151.503 28.5969L148.74 32.859C148.74 32.859 148.482 33.1548 148.246 33.1598C148.056 33.1967 147.851 33.1548 147.669 33.0266C147.486 32.8984 147.38 32.7555 147.334 32.5188C147.288 32.2822 147.334 32.1096 147.455 31.9223L150.188 27.7069L146.038 24.7905C146.038 24.7905 145.75 24.5194 145.742 24.2753C145.704 24.0781 145.742 23.8661 145.863 23.6788C145.984 23.4914 146.121 23.3829 146.349 23.3386C146.577 23.2942 146.744 23.3435 146.927 23.4717L151.038 26.3955L153.801 22.1334C153.801 22.1334 154.059 21.8376 154.287 21.7932C154.515 21.7489 154.682 21.7982 154.864 21.9264C155.046 22.0546 155.153 22.1976 155.199 22.4342C155.244 22.6709 155.199 22.8434 155.077 23.0308L152.315 27.2929L156.464 30.2093C156.464 30.2093 156.753 30.4805 156.799 30.7171C156.844 30.9538 156.799 31.1263 156.677 31.3137C156.556 31.501 156.419 31.6095 156.192 31.6538C155.964 31.6982 155.796 31.6489 155.614 31.5207L151.472 28.6438L151.503 28.5969Z"
        fill="white"
      />
      <path
        d="M63.3174 110.22L76.1511 107.723L76.7002 110.563L63.8665 113.06L63.3174 110.22ZM62.3947 105.447L75.2284 102.95L75.7775 105.79L62.9438 108.287L62.3947 105.447ZM64.2401 114.993L77.0738 112.496L77.6229 115.336L64.7892 117.833L64.2401 114.993ZM75.0301 92.142L42.0346 98.5608C40.0222 98.9523 38.6793 100.974 39.0911 103.104L43.88 127.876C44.2842 129.967 46.2525 131.385 48.3028 130.986L81.2983 124.567C83.3107 124.176 84.6534 122.155 84.2416 120.024L79.4527 95.2519C79.0486 93.1612 77.0805 91.7432 75.0301 92.142ZM80.551 120.701L64.0722 123.907L59.2833 99.1347L75.7622 95.9289L80.551 120.701Z"
        fill="#666666"
      />
      <path
        d="M139.807 98.6294C143.529 97.9048 145.927 94.2047 145.18 90.3394C144.434 86.4741 140.832 83.9422 137.11 84.6668C133.388 85.3915 130.989 89.0915 131.736 92.9568C132.483 96.8222 136.085 99.354 139.807 98.6294Z"
        fill="#F08413"
      />
      <path
        d="M140.485 102.144C136.004 103.016 127.453 107.095 128.352 111.749L128.687 113.484C128.87 114.431 129.782 115.072 130.693 114.894L154.276 110.303C155.188 110.126 155.795 109.189 155.612 108.243L155.277 106.507C154.378 101.853 144.966 101.271 140.447 102.151L140.485 102.144ZM148.939 87.3617C149.737 88.6386 150.034 90.1767 149.769 91.6606C149.625 92.548 149.914 93.4329 150.644 93.9456L150.682 93.9382C151.875 94.8108 153.508 94.2884 153.864 92.8686C154.667 90.0931 154.088 87.0957 152.287 84.7046C151.39 83.5288 149.673 83.6175 148.884 84.8351C148.436 85.577 148.467 86.5531 148.969 87.3148L148.939 87.3617ZM153.521 77.6303C152.975 78.4732 153.104 79.5529 153.796 80.2776C158.022 84.4066 159.264 90.8352 156.882 96.4552C156.502 97.3475 156.822 98.3902 157.605 98.9744L157.643 98.967C158.653 99.7115 160.043 99.359 160.544 98.2794C163.981 91.1854 162.351 82.7454 156.483 77.2582C155.579 76.4521 154.159 76.6468 153.491 77.6771L153.521 77.6303Z"
        fill="#F08413"
      />
      <path
        d="M95.4983 153.067L45.7924 162.737C38.2007 164.214 33.2824 171.805 34.8071 179.692L45.8269 236.697C47.3516 244.584 54.7418 249.781 62.3336 248.304L112.039 238.634C119.631 237.157 124.549 229.566 123.025 221.679L112.005 164.674C110.48 156.787 103.09 151.591 95.4983 153.067Z"
        fill="white"
      />
      <path
        d="M68.0182 192.717C68.8536 192.555 69.0655 191.408 68.3436 190.935L63.1913 187.312C62.7353 186.991 62.1656 187.102 61.8622 187.57L58.4332 192.863C57.978 193.565 58.6018 194.549 59.4372 194.386L61.8671 193.914L65.6266 213.361L63.1967 213.834C62.3613 213.997 62.1494 215.143 62.8713 215.616L68.0236 219.239C68.4796 219.56 69.0493 219.449 69.3527 218.981L72.7814 213.689C73.2366 212.986 72.6131 212.002 71.7777 212.165L69.3478 212.637L65.5883 193.19L68.0182 192.717ZM74.9668 191.365L93.6861 187.724C94.7113 187.524 95.3788 186.494 95.1729 185.429C94.967 184.364 93.964 183.658 92.9388 183.858L74.2195 187.499C73.1943 187.699 72.5268 188.729 72.7327 189.794C72.9386 190.859 73.9416 191.565 74.9668 191.365ZM97.4456 207.171L78.7263 210.813C77.7011 211.012 77.0336 212.043 77.2395 213.108C77.4454 214.173 78.4484 214.878 79.4736 214.679L98.1929 211.037C99.2181 210.838 99.8856 209.807 99.6797 208.742C99.4738 207.677 98.4708 206.972 97.4456 207.171ZM95.196 195.534L76.4767 199.176C75.4515 199.375 74.784 200.406 74.9899 201.471C75.1958 202.536 76.1989 203.241 77.224 203.042L95.9433 199.4C96.9685 199.201 97.636 198.17 97.4301 197.105C97.2242 196.04 96.2212 195.335 95.196 195.534Z"
        fill="#666666"
      />
      <path
        d="M149.782 173.555C150.125 175.33 151.812 176.517 153.521 176.184L160.773 174.773L165.142 197.376C165.486 199.152 167.173 200.338 168.881 200.005C170.59 199.673 171.713 197.94 171.37 196.165L167 173.562L174.252 172.151C175.961 171.819 177.084 170.086 176.741 168.311C176.397 166.536 174.71 165.349 173.002 165.682L152.308 169.707C150.6 170.04 149.477 171.773 149.82 173.548L149.782 173.555ZM143.179 189.329L146.292 188.723L148.58 200.557C148.923 202.333 150.61 203.519 152.319 203.186C154.028 202.854 155.15 201.121 154.807 199.346L152.519 187.512L155.633 186.906C157.342 186.574 158.464 184.841 158.121 183.066C157.778 181.291 156.091 180.105 154.382 180.437L141.966 182.852C140.258 183.185 139.135 184.918 139.478 186.693C139.821 188.468 141.508 189.654 143.217 189.322L143.179 189.329Z"
        fill="#666666"
      />
      <path
        d="M75.8442 230.907C77.2494 230.634 78.6553 231.588 78.9449 233.087C79.2268 234.546 78.3238 235.991 76.8807 236.272C75.4755 236.545 74.0694 235.591 73.7798 234.092C73.4978 232.633 74.4011 231.188 75.8442 230.907Z"
        fill="#AEAEAE"
      />
      <path
        d="M84.4227 229.24C85.8279 228.967 87.234 229.921 87.5236 231.42C87.8056 232.879 86.9023 234.324 85.4592 234.605C84.054 234.878 82.6481 233.924 82.3585 232.425C82.0766 230.966 82.9796 229.521 84.4227 229.24Z"
        fill="#AEAEAE"
      />
      <path
        d="M93.0023 227.574C94.4075 227.3 95.8134 228.254 96.103 229.753C96.3849 231.212 95.4819 232.657 94.0387 232.938C92.6336 233.211 91.2277 232.257 90.9381 230.758C90.6562 229.299 91.5592 227.854 93.0023 227.574Z"
        fill="#AEAEAE"
      />
      <path
        d="M194.254 464.037C199.719 443.571 205.184 423.105 210.649 402.639C212.857 394.37 208.515 386.519 200.583 384.386C197.231 383.485 193.912 382.593 190.56 381.691L186.412 380.576L175.692 377.694C162.749 374.214 149.374 370.618 136.231 367.084C134.505 366.62 132.643 366.267 130.754 366.018C128.139 365.684 125.533 366.386 122.717 368.14C119.383 370.235 117.15 373.402 116.037 377.57C111.777 393.523 107.453 409.716 103.267 425.393L100.322 436.418C98.0959 444.756 95.8693 453.094 93.6428 461.432C90.7999 472.078 87.9569 482.725 85.114 493.371L84.3227 496.334C80.1365 512.011 75.8031 528.239 71.5433 544.191L71.4145 544.673C70.9636 546.362 70.4944 548.119 70.3091 549.878C69.7166 555.96 73.2156 563.105 81.0148 565.201C81.1476 565.237 81.3135 565.282 81.4463 565.317C85.4565 566.285 89.5054 567.374 93.4124 568.461C95.0055 568.889 96.5985 569.318 98.1916 569.746C102.705 570.959 107.219 572.173 111.766 573.395C123.381 576.519 135.362 579.74 147.177 582.917C148.77 583.345 150.144 583.53 151.388 583.532C158.045 583.512 163.576 579.054 165.397 572.232C170.936 551.49 176.475 530.749 182.013 510.007L188.012 487.543L194.296 464.011L194.254 464.037Z"
        fill="#DFE3E6"
      />
      <path
        d="M205.088 408.416C205.088 408.416 206.744 402.215 207.25 399.655C208.317 394.329 206.113 390.191 201.183 388.274C199.123 387.462 196.933 386.873 194.842 386.311C194.277 386.159 193.713 386.008 193.149 385.856C191.39 385.383 190.485 385.841 189.935 387.503C189.02 390.396 186.026 391.216 183.537 390.547L183.504 390.538C174.685 388.13 165.724 385.72 157.019 383.417C153.8 382.551 150.614 381.695 147.394 380.829C147.162 380.767 146.93 380.704 146.697 380.642C146.166 380.499 145.602 380.347 145.071 380.204C142.941 379.521 141.729 378.198 141.393 376.261C141.284 375.604 141.401 374.897 141.519 374.19C141.583 373.949 141.615 373.699 141.646 373.449C141.789 372.38 141.411 371.798 140.439 371.574C139.311 371.27 138.182 370.967 137.054 370.663C136.025 370.387 134.963 370.101 133.934 369.825C130.829 369.064 128.092 369.325 125.579 370.606C122.61 372.135 120.642 374.707 119.704 378.222C117.533 386.353 78.7701 531.51 74.4402 547.592C73.7041 550.348 74.1349 552.864 75.7106 555.356C77.2623 557.804 79.6357 559.439 82.8883 560.314C98.1552 564.418 148.105 577.848 150.166 578.254C154.322 579.076 158.301 577.229 160.482 573.458C161.127 572.376 161.652 571.076 162.038 569.629C164.712 559.75 201.426 422.129 205.079 408.451L205.088 408.416Z"
        fill="#193E72"
      />
      <path
        d="M167.799 379.15L157.851 376.477C157.082 376.27 156.288 376.742 156.077 377.531C155.866 378.32 156.319 379.127 157.088 379.334L167.036 382.007C167.805 382.214 168.599 381.742 168.81 380.953C169.021 380.164 168.568 379.357 167.799 379.15Z"
        fill="#B2B2B2"
      />
      <path
        d="M173.434 384.389C174.533 384.685 175.671 383.999 175.976 382.858C176.281 381.716 175.637 380.551 174.538 380.256C173.439 379.96 172.3 380.646 171.996 381.788C171.691 382.929 172.335 384.094 173.434 384.389Z"
        fill="#B2B2B2"
      />
      <path
        d="M115.496 446.384C124.124 449.221 132.623 452.54 141.366 454.816C152.44 457.683 163.71 459.679 174.903 462.098C176.016 462.323 177.295 462.593 178.062 463.316C178.772 463.987 179.388 465.408 179.182 466.313C178.952 467.174 177.773 468.26 176.833 468.45C175.464 468.783 173.94 468.632 172.553 468.37C167.111 467.313 161.697 466.153 156.25 464.983C151.508 463.967 149.441 465.183 148.4 470.146C147.323 475.246 146.402 480.426 145.765 485.608C144.529 495.428 144.352 505.275 145.037 515.094C145.085 515.846 145.266 516.633 145.049 517.313C144.739 518.337 144.329 519.74 143.585 520.131C142.19 520.827 140.397 520.751 139.604 519.061C138.898 517.579 138.256 515.856 138.23 514.224C138.107 507.361 138.192 500.517 138.178 493.647C138.185 492.024 138.243 490.342 137.957 488.751C137.641 487.005 136.064 486.654 134.911 488.043C134.24 488.822 133.832 489.82 133.342 490.722C129.903 496.812 126.487 502.945 122.957 508.974C122.112 510.408 120.878 511.7 119.624 512.803C118.172 514.111 116.407 513.932 115.023 512.858C113.639 511.784 114.128 510.218 114.931 508.809C118.851 501.852 123.018 495.035 126.558 487.901C129.852 481.292 132.419 474.34 135.3 467.547C135.513 467.013 135.679 466.393 135.793 465.833C136.807 461.639 135.982 460.199 132.051 458.81C125.811 456.615 119.59 454.352 113.335 452.079C112.59 451.805 111.882 451.393 111.302 450.905C110.026 449.824 109.751 448.458 110.524 446.893C111.261 445.467 112.469 445.201 113.863 445.576C114.426 445.727 115.475 446.194 115.448 446.297L115.496 446.384Z"
        fill="white"
      />
      <path
        d="M146.084 451.992C140.378 450.457 136.95 444.256 138.475 438.279C139.967 432.293 146.247 428.48 152.01 430.066C157.806 431.662 161.231 438.01 159.555 444.02C157.906 449.927 151.823 453.535 146.075 452.026L146.084 451.992Z"
        fill="white"
      />
    </svg>
  );
};

export default Resources;
