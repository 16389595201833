// Libs
import { Container } from 'inversify';

// Core
import TYPES from './domain/Types';

// Apis
import Config from './infra/apis/Config';
import FirebaseApi from './infra/apis/FirebaseApi';
import DashboardApi from './infra/apis/DashboardApi';

// Repositories
import UserInfoRepository from './infra/repositories/UserInfoRepository';
import UserRepository from './infra/repositories/UserRepository';
import ReportRepository from './infra/repositories/ReportRepository';
import AverageReportRepository from './infra/repositories/AverageReportRepository';

// Infra
import CreateUserPasswordUseCase from './domain/use-cases/CreateUserPasswordUseCase';
import DecodeAuthTokenUseCase from './domain/use-cases/DecodeAuthTokenUseCase';
import { RemoteConfigProvider } from './infra/providers/RemoteConfigProvider';
import GetRemoteConfigUseCase from './domain/use-cases/remoteConfig/GetRemoteConfigUseCase';
import ParseRemoteConfigUseCase from './domain/use-cases/remoteConfig/ParseRemoteConfigUseCase';
import FilteredInsightReportUseCase from './domain/use-cases/FilteredInsightReportUseCase';
import SetRemoteConfigUseCase from './domain/use-cases/remoteConfig/SetRemoteConfigUseCase';

const container = new Container({
  defaultScope: 'Singleton',
});

container.bind(Config).toSelf();

container.bind(FirebaseApi).toSelf();
container.bind(DashboardApi).toSelf();

container.bind(UserRepository).toSelf();
container.bind(ReportRepository).toSelf();
container.bind(UserInfoRepository).toSelf();
container.bind(AverageReportRepository).toSelf();

// Providers
container.bind(RemoteConfigProvider).toSelf();
container.bind(TYPES.RemoteConfigProvider).to(RemoteConfigProvider);

// Use Cases
container.bind(TYPES.CreateUserPasswordUseCase).to(CreateUserPasswordUseCase);
container.bind(TYPES.DecodeAuthTokenUseCase).to(DecodeAuthTokenUseCase);
container.bind(TYPES.DecodeAuthTokenUseCase).to(DecodeAuthTokenUseCase);
container.bind(TYPES.GetRemoteConfigUseCase).to(GetRemoteConfigUseCase);
container.bind(GetRemoteConfigUseCase).toSelf();
container.bind(TYPES.SetRemoteConfigUseCase).to(SetRemoteConfigUseCase);
container.bind(SetRemoteConfigUseCase).toSelf();

container.bind(TYPES.ParseRemoteConfigUseCase).to(ParseRemoteConfigUseCase);
container.bind(ParseRemoteConfigUseCase).toSelf();
container.bind(TYPES.FilteredInsightReportUseCase).to(FilteredInsightReportUseCase);

export { container };
