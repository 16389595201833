/**
 * Gerencia o estado do componente DatepickerPopup
 */

import React from 'react';

const useCalendarModal = () => {
  const [shownCalendar, setShownCalendar] = React.useState<boolean>(false);
  const toggleCalendar = () => setShownCalendar(!shownCalendar);
  return {
    shownCalendar,
    toggleCalendar,
    hideCalendar: () => setShownCalendar(false),
    showCalendar: () => setShownCalendar(true),
  };
};

export default useCalendarModal;
