import { Document, Page, View, Image, Font, pdf } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import retrospective2024Br from '../../../../assets/images/retrospective2024.png';
import retrospective2024En from '../../../../assets/images/retrospective2024En.png';
import { PdfWordsSlide } from './wordsSlide';
import { PdfWelcomeSlide } from './welcomeSlide';
import { pdfStyles } from './styles';
import { PdfPagesSlide } from './pagesSlide';
import { RetroFormattedData } from '../../types';
import { PdfAddonsSlide } from './topAddonsSlide';

const registerFonts = (
  family: string,
  fontFiles: { file: string; weight: number; style?: string }[]
) => {
  fontFiles.forEach(({ file, weight, style }) => {
    Font.register({
      family,
      src: `/fonts/${family}/${file}`,
      fontWeight: weight,
      fontStyle: style,
    });
  });
};

registerFonts('Lato', [
  { file: 'Lato-Black.ttf', weight: 900 },
  { file: 'Lato-BlackItalic.ttf', weight: 1000, style: 'italic' },
  { file: 'Lato-Bold.ttf', weight: 700 },
  { file: 'Lato-Regular.ttf', weight: 400 },
]);

registerFonts('Raleway', [
  { file: 'Raleway-ExtraBold.ttf', weight: 800 },
  { file: 'Raleway-ExtraBoldItalic.ttf', weight: 800, style: 'italic' },
  { file: 'Raleway-Medium.ttf', weight: 500 },
  { file: 'Raleway-Regular.ttf', weight: 400 },
  { file: 'Raleway-SemiBold.ttf', weight: 600 },
  { file: 'Raleway-ExtraLight.ttf', weight: 200 },
]);

export const DefaultSlide = () => {
  const { i18n } = useTranslation('SlidePage');

  return (
    <View style={pdfStyles.defaultSlide}>
      {i18n.language === 'en' ? (
        <Image src={retrospective2024En} style={pdfStyles.defaultImage} />
      ) : (
        <Image src={retrospective2024Br} style={pdfStyles.defaultImage} />
      )}
    </View>
  );
};

export const handleDownloadRetroPdf = async (
  fileName: string,
  data: RetroFormattedData
) => {
  const pdfBlob = await pdf(
    <Document title={fileName}>
      <Page size="A4" style={pdfStyles.page}>
        <DefaultSlide />
      </Page>
      <Page size="A4" style={pdfStyles.page}>
        <PdfWelcomeSlide />
      </Page>
      {data.translatedWords && data.translatedWords !== '0' && (
        <Page size="A4" style={pdfStyles.page}>
          <PdfWordsSlide data={data.translatedWords} />
        </Page>
      )}
      {data.topPages.length && (
        <Page size="A4" style={pdfStyles.page}>
          <PdfPagesSlide data={data.topPages} />
        </Page>
      )}
      {data.topAddons.length && (
        <Page size="A4" style={pdfStyles.page}>
          <PdfAddonsSlide data={data.topAddons} />
        </Page>
      )}
    </Document>
  ).toBlob();

  const link = document.createElement('a');
  link.href = URL.createObjectURL(pdfBlob);
  link.download = `${fileName}.pdf`;
  link.click();
};
