import Calendar from '../../../../assets/svg/Calendar';
import {
  Container,
  Content,
  ContentImage,
  Header,
  Paragraphs,
  Subtitle,
  Title,
} from './styles';
import { useTranslation } from 'react-i18next';

interface WelcomeSlideProps {
  isPrintActive?: boolean;
}

export const WelcomeSlide = ({ isPrintActive }: WelcomeSlideProps) => {
  const { t: translate } = useTranslation('WelcomeSlide');

  const paragraphs = [
    { content: 'WelcomeFirstBody', highlighted: 'WelcomeFirstHighlighted' },
    { content: 'WelcomeSecondBody', highlighted: 'WelcomeSecondHighlighted' },
    { content: 'WelcomeTertiaryBody', highlighted: 'WelcomeTertiaryHighlighted' },
  ];

  return (
    <Container isPrintActive={isPrintActive}>
      <Content>
        <Header>
          <Title>{translate('WelcomeTitle')}</Title>
          <Subtitle>{translate('WelcomeSubTitle')}</Subtitle>
        </Header>
        <Paragraphs>
          {paragraphs.map(({ content, highlighted }) => {
            const [initial, final] = translate(highlighted)
              ? translate(content).split(translate(highlighted))
              : [translate(content)];

            return (
              <p key={content}>
                {initial}
                {highlighted && <strong>{translate(highlighted)}</strong>}
                {final && final}
              </p>
            );
          })}
        </Paragraphs>
      </Content>
      <ContentImage>
        <Calendar alt={translate('CalendarAlt')} />
      </ContentImage>
    </Container>
  );
};
