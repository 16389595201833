/** Libs */
import React from 'react';

/** Interfaces */
import { ICalendar } from './interfaces';

/** Services */
import { makeCalendar } from '../utils/makeCalendar';

/** Styles */
import { Container } from './styles';

/** Components */
import { CalendarHeader } from '../CalendarHeader';
import { CalendarWeekDays } from '../CalendarWeekDays';

const Calendar: React.FC<ICalendar> = ({
  children,
  monthTimestamp,
  onPrevMonth,
  onNextMonth,
}) => {
  const calendar = React.useMemo(() => makeCalendar(monthTimestamp), [monthTimestamp]);

  return (
    <Container>
      <CalendarHeader
        monthTimestamp={monthTimestamp}
        onPrevMonth={onPrevMonth}
        onNextMonth={onNextMonth}
      />
      <table>
        <thead>
          <CalendarWeekDays />
        </thead>
        <tbody>
          {calendar.map((week, index) => (
            <tr key={String(index)}>
              {week.map((day) => (
                <td key={day.timestamp}>{children(day)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

const CalendarMemo = React.memo(Calendar);
export { CalendarMemo as Calendar };
