import { Image, Text, View } from '@react-pdf/renderer';
import hugoPagesImg from '../../../../assets/images/hugoPages.png';
import { useTranslation } from 'react-i18next';
import { pdfStyles } from './styles';
import { truncateText } from '../../../../../infra/services/helper';

interface PdfPagesSlideProps {
  data: { value: string }[];
}

export const PdfPagesSlide = ({ data }: PdfPagesSlideProps) => {
  const { t: translate } = useTranslation('PagesSlide');

  return (
    <View style={{ ...pdfStyles.slideBetween, backgroundColor: '#0B7A6A' }}>
      <View style={pdfStyles.content}>
        <Text style={{ ...pdfStyles.title, color: '#fff', fontSize: '38px' }}>
          {translate('PagesTitle')}
        </Text>
        <Text style={{ ...pdfStyles.subtitle, color: '#fff' }}>
          {translate('PagesFirstBody')}
        </Text>
        <View style={pdfStyles.metrics}>
          {data.map((row) => (
            <Text key={row.value} style={{ ...pdfStyles.topMetric, color: '#96F9E0' }}>
              {truncateText(`${row.value}`, 40)}
            </Text>
          ))}
        </View>
        <Text style={{ ...pdfStyles.finalContent, color: '#ffff' }}>
          {translate('PagesTertiaryBody')}
        </Text>
      </View>
      <Image src={hugoPagesImg} style={pdfStyles.bottomImage} />
    </View>
  );
};
