import { YButton } from '@hand-talk/yotta-components';
import RetrospectiveEN from '../../../../assets/svg/RetrospectiveEN';
import RetrospectivePT from '../../../../assets/svg/RetrospectivePT';
import { useTranslation } from 'react-i18next';
import { Container, ContentInitial } from './styles';

interface DefaultSlideProps {
  goToNext: () => void;
}

export const DefaultSlide = ({ goToNext }: DefaultSlideProps) => {
  const { t: translateSlidePage, i18n } = useTranslation('SlidePage');

  return (
    <Container>
      <ContentInitial>
        {i18n.language === 'en' ? (
          <RetrospectiveEN alt={translateSlidePage('AltRetrospectiveImage')} />
        ) : (
          <RetrospectivePT alt={translateSlidePage('AltRetrospectiveImage')} />
        )}
        <YButton
          variation="tonal"
          size="total"
          onClick={() => goToNext()}
          title={translateSlidePage('startBtnAlt')}
          aria-label={translateSlidePage('startBtnAlt')}
        >
          {translateSlidePage('ButtonInital')}
        </YButton>
      </ContentInitial>
    </Container>
  );
};
