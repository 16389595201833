import styled from 'styled-components';
import { mobileMediaQuery } from '../../../infra/services/helper';

export const SubheaderContainer = styled.div`
  height: 60px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) =>
    mobileMediaQuery(`
    height: fit-content;
    flex-direction: column;
    gap: ${theme.spacing[5]};
  `)}

  ul,
  li,
  li > button {
    height: 60px !important;
  }
`;
