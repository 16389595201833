/** Libs */
import React from 'react';
import { DefaultTheme, useTheme } from 'styled-components';

/** Styles */
import { Container, Wrapper } from './styles';

interface IContainer {
  children: React.ReactNode;
  className?: string;
  styles?: React.CSSProperties;
  backgroundColor?: (theme: DefaultTheme) => string;
}

const CustomContainer: React.FC<IContainer> = ({
  children,
  className,
  styles,
  backgroundColor,
}): JSX.Element => {
  const theme = useTheme();

  return (
    <Wrapper
      style={{
        backgroundColor: backgroundColor ? backgroundColor(theme) : 'transparent',
      }}
    >
      <Container style={styles} className={className}>
        {children}
      </Container>
    </Wrapper>
  );
};

export default CustomContainer;
