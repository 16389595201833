/** Libs */
import styled from 'styled-components';

/** Services */
import {
  getIconStyle,
  mobileMediaQuery,
  tabletMediaQuery,
} from '../../../infra/services/helper';

/** Tokens */
import { brand, neutral } from '../../assets/tokens/colors';
import { border } from '../../assets/tokens/borders';

export const Section = styled.section<{
  marginType?: 'none' | 'customFooter';
}>`
  background: ${neutral.color.high.lightest};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  width: 100%;
  border-radius: ${border.radius.md};
  padding: 20px 16px;
  margin-top: 18px;
  margin-bottom: ${(props) =>
    props.marginType === 'none'
      ? '0'
      : props.marginType === 'customFooter'
      ? '42px'
      : '18px'};

  ${tabletMediaQuery(`
    margin: 24px 0;
  `)}

  ${mobileMediaQuery(`
    margin: 32px 0;
  `)}
`;

export const TopCard = styled.div<{ noTopCardMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.noTopCardMargin ? 0 : '32px')};
`;

export const StyledIcon = styled.span`
  ${getIconStyle('32px', brand.color.main)};
`;
