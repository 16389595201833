import { inject, injectable } from 'inversify';
import { RemoteConfigEntity } from '../../domain/entities/RemoteConfigEntity';
import { IRemoteConfigProvider } from './interfaces/IRemoteConfigProvider';
import DashboardApi from '../apis/DashboardApi';
import { RemoteConfigSchema } from '../../domain/schemas/remoteConfig/RemoteConfigSchema';
import { IUserLogPayload } from '../../domain/types/remoteConfig';

type IConfigResponse = {
  hasTexture: boolean;
  remoteConfigs: RemoteConfigSchema[];
} | null;

@injectable()
export class RemoteConfigProvider implements IRemoteConfigProvider {
  @inject(DashboardApi) private dashboardApi: DashboardApi;

  public async getConfig(token: string, userEmail: string): Promise<RemoteConfigEntity> {
    const data: IConfigResponse = await this.dashboardApi.get('getRemoteConfig', {
      userEmail,
      accessKey: token,
    });

    const config: RemoteConfigSchema = {
      hasTexture: !!data?.hasTexture,
      isActive: false,
      config: {},
      sid: '',
      isMajority: false,
      ...(data?.remoteConfigs[0] || {}),
    };

    return new RemoteConfigEntity(config.id ?? '', config);
  }

  public async setConfig(
    accessKey: string,
    remoteConfig: RemoteConfigSchema,
    userEmail: string,
    userInfo: IUserLogPayload
  ): Promise<RemoteConfigEntity | null> {
    const data: IConfigResponse = await this.dashboardApi.post('setRemoteConfig', {
      remoteConfig,
      accessKey,
      userEmail,
      userInfo,
    });

    let config: RemoteConfigSchema | null = null;

    if (data?.remoteConfigs) {
      config = { hasTexture: data.hasTexture, ...data.remoteConfigs[0] };
    }

    return config ? new RemoteConfigEntity(config.id ?? '', config) : null;
  }
}
