// Libs
import styled from 'styled-components';

// Tokens
import { neutral } from '../../../assets/tokens/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23.5px 0;
`;

export const ContainerMonth = styled.div`
  font: ${({ theme }) => theme.typography.paragraph.mdBold};
  color: ${neutral.color.low.main};

  padding: 0 24px;
  display: flex;
  justify-content: center;
  gap: 5px;
`;
