// Libs
import { YIconButton, YMenu, YMenuItem } from '@hand-talk/yotta-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Services
import * as Analytics from '../../../infra/services/analytics';

// Components
import LogoutIcon from '../../assets/icons/LogoutIcon';
import { MenuContainer } from './Styles';

interface IMenuProfileProps {
  onClickLogout?: () => void;
}

export default function Profile({ onClickLogout }: IMenuProfileProps) {
  const [open, setOpen] = useState<boolean>();
  const { t } = useTranslation('Menu');

  const handleLogout = async () => {
    await Analytics.logoutEvent();

    if (onClickLogout) {
      onClickLogout();
    }
  };

  return (
    <MenuContainer>
      <YIconButton onClick={() => setOpen(true)}>
        <LogoutIcon />
      </YIconButton>
      {open && (
        <YMenu onClose={setOpen} position={{ right: '0px', top: '53px' }} zIndex={2}>
          <YMenuItem
            alt-text={t('altTextLogout')}
            title={t('altTextLogout')}
            onClick={handleLogout}
            icon={<LogoutIcon />}
          >
            {t('logout')}
          </YMenuItem>
        </YMenu>
      )}
    </MenuContainer>
  );
}
