import axios from 'axios';

export const getCurrentUserIp = async (): Promise<string> => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip || '';
  } catch (error) {
    return '';
  }
};
