import { IAccessKey, IInfo } from '../../infra/services/interfaces';

export interface IPartialReport {
  accessKey: IAccessKey;
  hasRetro: boolean;
  info: IInfo;
}

export interface IUserInfoSchema {
  language: 'ptBR' | 'en';
  displayName: string;
  reports: IPartialReport[];
}

export default class UserInfoModel {
  constructor(private schema: IUserInfoSchema) {}

  public get reports() {
    return this.schema.reports;
  }
}
