import { StyleSheet } from '@react-pdf/renderer';

export const pdfStyles = StyleSheet.create({
  viewer: {
    height: '600px',
    width: '100%',
  },
  page: {
    height: '100%',
    width: '100%',

    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  defaultSlide: {
    backgroundColor: '#C64F01',

    padding: 10,
    flexGrow: 1,

    alignItems: 'center',
    justifyContent: 'center',
  },
  defaultImage: {
    width: '320px',
  },
  welcomeSlide: {
    padding: 24,
    flexGrow: 1,

    backgroundColor: '#C64F01',

    alignItems: 'center',
    gap: '128px',
  },
  welcomeContent: {
    alignItems: 'flex-start',
    paddingTop: '48px',
  },
  welcomeSlideTitle: {
    fontFamily: 'Raleway',
    fontWeight: 200,
    fontSize: '48px',
    color: '#FFE0A8',
  },
  welcomeSlideSubtitle: {
    fontFamily: 'Lato',
    fontSize: '48px',
    fontWeight: 900,
    fontStyle: 'italic',

    color: '#FFE0A8',
    marginBottom: '24px',
  },
  welcomeSlideParagraph: {
    marginBottom: '16px',
    color: '#FFE0A8',
    lineHeight: '150%',
  },
  welcomeSlideStrong: {
    fontFamily: 'Lato',
    color: '#FFE0A8',
    fontWeight: 900,
  },
  welcomeImage: {
    width: '195px',
  },
  slideBetween: {
    flexGrow: 1,
    paddingHorizontal: 24,

    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    alignItems: 'center',
    paddingTop: '48px',
  },
  title: {
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '16px',
    fontSize: '42px',
  },
  subtitle: {
    fontFamily: 'Lato',
    fontWeight: 900,

    textAlign: 'center',
    fontSize: '24px',
  },
  metric: {
    fontFamily: 'Lato',

    textAlign: 'center',
    padding: '24px',
    fontSize: '48px',
    fontWeight: 900,
    color: '#fff',
  },
  metrics: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2px',

    color: 'red',
    marginTop: '32px',
  },
  topMetric: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontStyle: 'italic',
    textAlign: 'center',
    fontSize: '24px',
  },
  finalContent: {
    textAlign: 'center',
    padding: '16px',
    marginTop: '32px',

    lineHeight: '130%',
  },
  bottomImage: {
    height: '300px',
  },
});
