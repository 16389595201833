enum TYPES {
  DecodeAuthTokenUseCase = 'ExtractAuthUserFromTokenUseCase',
  CreateUserPasswordUseCase = 'CreateUserPasswordUseCase',
  RemoteConfigProvider = 'RemoteConfigProvider',
  GetRemoteConfigUseCase = 'GetRemoteConfigUseCase',
  FilteredInsightReportUseCase = 'FilteredInsightReportUseCase',
  ParseRemoteConfigUseCase = 'ParseRemoteConfigUseCase',
  SetRemoteConfigUseCase = 'SetRemoteConfigUseCase',
}

export default TYPES;
