type BadgeProps = {};

const BadgeHundredThousand: React.FC<BadgeProps> = () => {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5662_22710)">
        <path
          d="M123.528 106.664L77.6605 60.7966C77.2803 60.4165 76.7647 60.203 76.2271 60.203C75.6895 60.203 75.1739 60.4165 74.7937 60.7966L54.9179 80.6724C54.5378 81.0526 54.3242 81.5683 54.3242 82.1059C54.3242 82.6436 54.5378 83.1592 54.9179 83.5395L100.785 129.406C101.058 129.68 101.404 129.869 101.782 129.952C102.159 130.035 102.553 130.009 102.916 129.876C103.278 129.743 103.596 129.509 103.83 129.202C104.065 128.894 104.206 128.526 104.239 128.141L105.625 111.504L122.263 110.117C122.648 110.085 123.016 109.943 123.324 109.709C123.631 109.475 123.865 109.157 123.998 108.794C124.131 108.431 124.157 108.038 124.074 107.66C123.991 107.283 123.801 106.937 123.528 106.664Z"
          fill="#FF8C29"
        />
        <path
          d="M65.177 70.4133L54.9179 80.6724C54.5378 81.0526 54.3242 81.5683 54.3242 82.1059C54.3242 82.6436 54.5378 83.1592 54.9179 83.5395L100.785 129.406C101.058 129.68 101.404 129.869 101.782 129.952C102.159 130.035 102.553 130.009 102.916 129.876C103.278 129.743 103.596 129.509 103.83 129.202C104.065 128.894 104.206 128.526 104.239 128.141L105.625 111.504L106.219 111.454L65.177 70.4133Z"
          fill="#FD6A33"
        />
        <path
          d="M75.0824 80.6724L55.2066 60.7966C54.8263 60.4165 54.3107 60.203 53.7731 60.203C53.2355 60.203 52.72 60.4165 52.3397 60.7966L6.47232 106.664C6.199 106.937 6.00954 107.283 5.92629 107.66C5.84304 108.038 5.86946 108.431 6.00245 108.794C6.13544 109.157 6.36944 109.475 6.67686 109.709C6.98429 109.943 7.35231 110.085 7.73753 110.117L24.3755 111.504L25.7621 128.141C25.8041 128.648 26.0351 129.12 26.4092 129.465C26.7833 129.809 27.2732 130 27.7817 130C28.0479 130 28.3116 129.948 28.5576 129.846C28.8036 129.744 29.0272 129.595 29.2155 129.406L75.0824 83.5395C75.4625 83.1592 75.676 82.6436 75.676 82.1059C75.676 81.5683 75.4625 81.0526 75.0824 80.6724Z"
          fill="#FF8C29"
        />
        <path
          d="M65.3634 70.9538L24.4088 111.909L25.7616 128.141C25.8036 128.648 26.0346 129.12 26.4087 129.465C26.7828 129.809 27.2727 130 27.7812 130C28.0474 130 28.3111 129.948 28.5571 129.846C28.8031 129.744 29.0267 129.595 29.215 129.407L75.0821 83.5397C75.4622 83.1595 75.6758 82.6438 75.6758 82.1062C75.6758 81.5685 75.4622 81.0529 75.0821 80.6726L65.3634 70.9538Z"
          fill="#FD6A33"
        />
        <path
          d="M113.871 40.1261L101.058 29.3742L98.1531 12.901C98.0703 12.4317 97.8249 12.0065 97.4598 11.7002C97.0947 11.3938 96.6334 11.2259 96.1569 11.226H79.4295L66.6161 0.474299C66.251 0.167884 65.7896 -7.62939e-05 65.313 -7.62939e-05C64.8363 -7.62939e-05 64.3749 0.167884 64.0098 0.474299L51.1964 11.2262H34.4693C33.9927 11.2261 33.5314 11.394 33.1663 11.7004C32.8012 12.0067 32.5558 12.4319 32.4731 12.9012L29.5682 29.3742L16.7548 40.1261C16.3897 40.4324 16.1443 40.8575 16.0615 41.3268C15.9788 41.7962 16.064 42.2796 16.3023 42.6923L24.6655 57.1784L21.761 73.6511C21.6782 74.1204 21.7633 74.604 22.0016 75.0167C22.2399 75.4295 22.616 75.7451 23.0638 75.9081L38.7822 81.6291L47.1456 96.1152C47.3838 96.528 47.7599 96.8436 48.2078 97.0067C48.6557 97.1697 49.1466 97.1697 49.5945 97.0067L65.3126 91.2856L81.0306 97.0067C81.4785 97.1695 81.9694 97.1694 82.4171 97.0064C82.8649 96.8433 83.241 96.5278 83.4793 96.1152L91.8427 81.6291L107.561 75.9081C108.009 75.7451 108.385 75.4295 108.623 75.0167C108.861 74.6039 108.947 74.1204 108.864 73.6511L105.959 57.1784L114.323 42.6923C114.562 42.2798 114.647 41.7963 114.565 41.327C114.482 40.8576 114.237 40.4324 113.871 40.1261Z"
          fill="#48C1F8"
        />
        <path
          d="M65 17.3977C47.1908 17.3977 32.7021 31.8863 32.7021 49.6955C32.7021 67.5048 47.1908 81.9932 65 81.9932C82.8092 81.9932 97.2979 67.5045 97.2979 49.6953C97.2979 31.8861 82.8092 17.3977 65 17.3977Z"
          fill="#FD6A33"
        />
        <path
          d="M88.3289 43.2405C88.1967 42.8333 87.9389 42.4785 87.5926 42.2268C87.2462 41.9752 86.8291 41.8397 86.401 41.8397H71.4406L66.8175 27.6113C66.6853 27.2041 66.4275 26.8491 66.0812 26.5974C65.7348 26.3457 65.3177 26.2102 64.8895 26.2102C64.4614 26.2102 64.0443 26.3457 63.6979 26.5974C63.3516 26.8491 63.0938 27.2041 62.9616 27.6113L58.3385 41.8397H43.3778C42.9498 41.8397 42.5327 41.9753 42.1864 42.227C41.8401 42.4786 41.5824 42.8334 41.4501 43.2405C41.3179 43.6477 41.3179 44.0862 41.4502 44.4933C41.5825 44.9004 41.8402 45.2552 42.1865 45.5069L54.29 54.3004L49.6669 68.529C49.5345 68.9362 49.5344 69.3747 49.6667 69.7819C49.7989 70.1891 50.0567 70.5439 50.403 70.7955C50.7494 71.0472 51.1665 71.1827 51.5946 71.1827C52.0227 71.1827 52.4398 71.0471 52.7861 70.7954L64.8895 62.0021L76.9928 70.7954C77.3391 71.0467 77.7561 71.182 78.1841 71.1819C78.612 71.1817 79.0289 71.0462 79.3751 70.7946C79.7214 70.5431 79.9791 70.1884 80.1114 69.7815C80.2438 69.3745 80.244 68.9361 80.112 68.529L75.4889 54.3004L87.5923 45.5069C87.9387 45.2553 88.1965 44.9005 88.3288 44.4933C88.4611 44.0862 88.4612 43.6476 88.3289 43.2405Z"
          fill="white"
        />
        <path
          d="M64.01 0.474294L51.1966 11.2262H34.4696C33.993 11.2261 33.5316 11.394 33.1666 11.7004C32.8015 12.0067 32.556 12.4319 32.4733 12.9012L29.5684 29.3742L16.755 40.1261C16.39 40.4324 16.1445 40.8575 16.0618 41.3268C15.979 41.7962 16.0643 42.2796 16.3026 42.6923L24.6657 57.1784L21.7613 73.6511C21.6784 74.1204 21.7636 74.604 22.0018 75.0167C22.2401 75.4295 22.6162 75.7451 23.0641 75.9081L38.7824 81.6291L47.1458 96.1152C47.3841 96.528 47.7602 96.8436 48.208 97.0067C48.6559 97.1697 49.1469 97.1697 49.5947 97.0067L64.9997 91.3996V0.0266571C64.645 0.0822626 64.3015 0.229782 64.01 0.474294Z"
          fill="#3B98D2"
        />
        <path
          d="M32.7021 49.6953C32.7021 67.5045 47.1908 81.9932 65 81.9932V17.3977C47.191 17.3977 32.7021 31.8866 32.7021 49.6953Z"
          fill="#F0353D"
        />
        <path
          d="M64.8895 26.211C64.4615 26.211 64.0444 26.3466 63.698 26.5982C63.3517 26.8498 63.0939 27.2047 62.9616 27.6118L58.3385 41.8402H43.3778C42.9498 41.8403 42.5327 41.9758 42.1864 42.2275C41.8401 42.4791 41.5824 42.8339 41.4501 43.241C41.3179 43.6482 41.3179 44.0867 41.4502 44.4938C41.5825 44.9009 41.8402 45.2557 42.1865 45.5074L54.29 54.3009L49.6669 68.5296C49.5345 68.9367 49.5344 69.3753 49.6667 69.7824C49.7989 70.1896 50.0567 70.5444 50.403 70.7961C50.7494 71.0477 51.1665 71.1832 51.5946 71.1832C52.0227 71.1832 52.4398 71.0476 52.7861 70.7959L64.8895 62.0026L65 62.0826V26.2196C64.9629 26.2179 64.9269 26.211 64.8895 26.211Z"
          fill="#FFD1A9"
        />
      </g>
      <defs>
        <clipPath id="clip0_5662_22710">
          <rect width="130" height="130" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BadgeHundredThousand;
