import Header from '../../components/Header';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import Menu from '../../components/Menu';
import useAuth from '../../hooks/useAuth';
import { IPrimaryLayoutRoute } from '../../routes';
import { Main } from './styles';

// Services
import * as Analytics from '../../../infra/services/analytics';
import { SubHeader } from '../../components/SubHeader';

interface IPrimaryLayout extends Partial<IPrimaryLayoutRoute> {
  children: JSX.Element;
}

export const PrimaryLayout: React.FC<IPrimaryLayout> = ({ children, ...props }) => {
  const { signOut, authState } = useAuth();

  const handleLogout = async () => {
    await signOut();
    await Analytics.logoutEvent();
    window.location.href = '/';
  };

  const header = (
    <>
      <Header.Navbar>
        <LanguageSwitcher />
        {authState.type === 'success' && <Menu.Profile onClickLogout={handleLogout} />}
      </Header.Navbar>
      {!props.onlyHeader && (
        <Header.Menu>
          <Header.Containers.Domain>
            <SubHeader authStateType={authState.type} />
          </Header.Containers.Domain>
        </Header.Menu>
      )}
    </>
  );

  return (
    <Main>
      {header}
      {children}
    </Main>
  );
};
