// Hooks
import useResetPassword from '../../hooks/useResetPassword';

// Components
import AuthForms from '../../components/AuthForms';
import Container from '../../components/Container';
import LoadingAnimated from '../../components/LoadingAnimated/index';

export default function ResetPassword() {
  const { resetPassword, resetPasswordState } = useResetPassword();

  const handleResetPassword = async (email: string) => {
    await resetPassword(email);
  };

  if (resetPasswordState.type === 'loading') {
    return (
      <Container>
        <LoadingAnimated />
      </Container>
    );
  }

  return (
    <Container>
      <AuthForms.ResetPassword
        onSubmit={handleResetPassword}
        success={resetPasswordState.type === 'success'}
      />
    </Container>
  );
}
