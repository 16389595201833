import React from 'react';
import FocusLock from 'react-focus-lock';

// Interfaces
import { IDatepickerPopup } from './interfaces';

// Styles
import { Wrapper, WrapperDatepickerPopup, Backdrop } from './styles';

export const DatepickerPopup: React.FC<IDatepickerPopup> = ({
  shownCalendar,
  hide,
  headerText,
  children,
}) => {
  // Fecha o modal ao pressionar a tecla ESC
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && shownCalendar) {
      hide();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [shownCalendar]);

  if (!shownCalendar) return null;

  return (
    <>
      <Backdrop shown={shownCalendar} onClick={hide} />
      <FocusLock>
        <Wrapper
          aria-modal
          aria-labelledby={headerText}
          tabIndex={-1}
          role="dialog"
          shown={shownCalendar}
        >
          <WrapperDatepickerPopup>{children}</WrapperDatepickerPopup>
        </Wrapper>
      </FocusLock>
    </>
  );
};
