/** Libs */
import styled from 'styled-components';

/** Tokens */
import { brand, highlight, neutral } from '../../assets/tokens/colors';
import { font } from '../../assets/tokens/fonts';

/** Services */
import { getIconStyle, tabletMediaQuery } from '../../../infra/services/helper';
import { IDataCard } from '../../../infra/services/interfaces';

const getPadding = (props: IDataCard) => {
  if (props.small) {
    return '17px 0 17px 8px';
  }
  return '20.5px 0 20.5px 8px';
};

/** Base */

export const Section = styled.section`
  max-width: 672px;
  width: 100%;
  background: ${neutral.color.high.lightest};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 16px;

  ${tabletMediaQuery(`
    max-width: 100%;
    padding: 8px;
  `)}
`;

export const TopCard = styled.div`
  margin-bottom: 16px;
  ${getIconStyle('32px', brand.color.main)};
`;

/** Base */

export const ContentAll = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 18px 0 24px;

  .CardUsers {
    margin-left: 16px;
  }

  ${tabletMediaQuery(`
    flex-direction: column;

    .CardUsers {
      margin: 16px 0 0;
    }
  `)}
`;

export const DataCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid ${neutral.color.high.light};
  width: 100%;
  padding: ${getPadding};
`;

export const StyledMetric = styled.p`
  font: ${font.heading.medium};
  font-family: ${font.family};
  color: ${highlight.color.medium};
`;

export const InfoData = styled.p`
  font: ${font.paragraph.Xsmall};
  font-family: ${font.family};
  color: ${neutral.color.low.main};
`;
