import { createContext, useCallback, useContext, useState } from 'react';
import { RemoteConfigSchema } from '../../../domain/schemas/remoteConfig/RemoteConfigSchema';
import { useInjection } from 'inversify-react';
import GetRemoteConfigUseCase from '../../../domain/use-cases/remoteConfig/GetRemoteConfigUseCase';
import { RemoteConfigLocalValues } from '../../../domain/types/remoteConfig';
import { addonsKeys, commonsConfigKeys } from '../../../core/common/remoteConfig';
import ParseRemoteConfigUseCase from '../../../domain/use-cases/remoteConfig/ParseRemoteConfigUseCase';
import { RemoteConfigEntity } from '../../../domain/entities/RemoteConfigEntity';
import SetRemoteConfigUseCase from '../../../domain/use-cases/remoteConfig/SetRemoteConfigUseCase';
import { getCurrentUserIp } from '../../../infra/services/getUserIp';

const mandatoryConfigKeys = [...commonsConfigKeys];

interface RequestedRemoteConfig {
  localFormat: RemoteConfigLocalValues | null;
  remoteFormat: RemoteConfigEntity | null;
}

interface IRemoteConfigContext {
  addonsKeys: string[];
  requestedRemoteConfig: RequestedRemoteConfig;
  parseLocalToRemoteConfig: (data: RemoteConfigLocalValues) => RemoteConfigSchema;
  getRemoteConfig: (accessKey: string, userEmail: string) => Promise<void>;
  saveConfig: (
    accessKey: string,
    userEmail: string,
    userId: string,
    draftConfig: RemoteConfigLocalValues
  ) => Promise<boolean>;
  validateRemoteConfigFilling: (data: RemoteConfigLocalValues) => boolean;
}

const RemoteConfigContext = createContext<IRemoteConfigContext>({
  addonsKeys,
  requestedRemoteConfig: {
    localFormat: null,
    remoteFormat: null,
  },
  parseLocalToRemoteConfig: () => ({
    sid: '',
    config: {},
    isActive: false,
    isMajority: false,
  }),
  getRemoteConfig: async () => {},
  saveConfig: async () => true,
  validateRemoteConfigFilling: () => false,
});

interface IRemoteConfigProvider {
  children: JSX.Element;
}

export const RemoteConfigContextProvider = ({ children }: IRemoteConfigProvider) => {
  const getRemoteConfigUseCase = useInjection(GetRemoteConfigUseCase);
  const setRemoteConfigUseCase = useInjection(SetRemoteConfigUseCase);
  const parseRemoteConfigUseCase = useInjection(ParseRemoteConfigUseCase);
  const [requestedRemoteConfig, setRequestedRemoteConfig] =
    useState<RequestedRemoteConfig>({
      localFormat: null,
      remoteFormat: null,
    });

  const parseLocalToRemoteConfig = (configDraft: RemoteConfigLocalValues) => {
    const parsedConfig = parseRemoteConfigUseCase.parseFromLocalConfig(
      configDraft,
      requestedRemoteConfig?.remoteFormat
    );

    return {
      ...parsedConfig.toObject(),
      id: requestedRemoteConfig.remoteFormat?.id,
    };
  };

  const validateRemoteConfigFilling = useCallback(
    (configDraft: RemoteConfigLocalValues) => {
      const draftKeys = Object.keys(configDraft);
      let isFilled: boolean = true;

      mandatoryConfigKeys.map((configKey) => {
        if (!isFilled) return configKey;

        // Avatar and addonsEnabled are optional
        if (!['addonsEnabled', 'avatar'].includes(configKey)) {
          isFilled = draftKeys.includes(configKey);
        }

        return configKey;
      });

      return isFilled;
    },
    []
  );

  const getRemoteConfig = useCallback(async (accessKey: string, userEmail: string) => {
    const remoteConfig = await getRemoteConfigUseCase.execute(accessKey, userEmail);

    const localFormat = parseRemoteConfigUseCase.parseToLocalConfig(remoteConfig);

    setRequestedRemoteConfig({
      remoteFormat: remoteConfig,
      localFormat,
    });
  }, []);

  const saveConfig = useCallback(
    async (
      accessKey: string,
      userEmail: string,
      userId: string,
      draftConfig: RemoteConfigLocalValues
    ) => {
      const currentIp = await getCurrentUserIp();

      const response = await setRemoteConfigUseCase.execute(
        accessKey,
        parseLocalToRemoteConfig(draftConfig),
        userEmail,
        { IP: currentIp, domain: window.location.hostname, userId }
      );

      return !!response?.id;
    },
    []
  );

  return (
    <RemoteConfigContext.Provider
      value={{
        requestedRemoteConfig,
        addonsKeys,
        parseLocalToRemoteConfig,
        getRemoteConfig,
        saveConfig,
        validateRemoteConfigFilling,
      }}
    >
      {children}
    </RemoteConfigContext.Provider>
  );
};

export const useRemoteConfigContext = () => {
  return useContext(RemoteConfigContext);
};
