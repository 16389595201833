/** Libs */
import React, { useState } from 'react';
import { YContentTab, YIcon, YTabPane, YTabs } from '@hand-talk/yotta-components';
import { useTranslation } from 'react-i18next';

/** Utils */
import GetDataDaily from './getDataDaily';
import GetDataAcumulative from './getDataAcumulative';
import GetMonthlyTranslations from './getDataMonthly';
import { Acumulative, Daily, Monthly } from './Graphics';

/** Components */
import CardHelper from '../CardHelper';
import CustomContainer from '../CustomContainer';
import Title from '../Title';
import CustomSelect from '../CustomSelect';

/** Services */
import { IChart, Language } from '../../../infra/services/interfaces';
import { getStoredItem, setStoredItem } from '../../../infra/services/localStorage';
import { isMobile } from '../../../infra/services/helper';
import { TabsContainer } from './styles';

type ChartTabOptions = 'ACCUMULATIVE' | 'DAILY' | 'MONTHLY';
interface ChartTypeOptions {
  id: ChartTabOptions;
  titleKey: string;
}

const Chart: React.FC<IChart> = ({ translations, filterLanguages }) => {
  const { t: translationChart, i18n } = useTranslation('Chart');
  const [selectedChartTab, setSelectedChartTab] =
    useState<ChartTabOptions>('ACCUMULATIVE');

  const chartTypeOptions: ChartTypeOptions[] = [
    { id: 'ACCUMULATIVE', titleKey: 'accumulativeTabTitle' },
    { id: 'DAILY', titleKey: 'dailyTabTitle' },
    { id: 'MONTHLY', titleKey: 'monthlyTabTitle' },
  ];

  /**
   * Carrega a língua do localStorage ou usa a primeira do filtro se não houver nenhuma armazenada
   */
  const [selectedLanguage, setSelectedLanguage] = React.useState<Language>(
    getStoredItem<Language>('selectedLanguage') || filterLanguages[0]
  );

  React.useEffect(() => {
    /**
     * Atualiza a língua armazenada em selectedLanguage.
     */
    setStoredItem('selectedLanguage', selectedLanguage);
  }, [selectedLanguage]);

  const handleSelectTab = (currentId: string) =>
    setSelectedChartTab(currentId as ChartTabOptions);

  const chartDataDaily = GetDataDaily(translations, selectedLanguage, i18n.language);
  const chartDataAcumulative = GetDataAcumulative(
    translations,
    selectedLanguage,
    i18n.language
  );
  const chartDataMonthly = GetMonthlyTranslations(
    translations,
    selectedLanguage,
    i18n.language
  );

  const getCurrentChart = (currentType: ChartTabOptions) =>
    currentType === 'ACCUMULATIVE' ? (
      <Acumulative
        translationsData={chartDataAcumulative}
        translation={translationChart}
      />
    ) : currentType === 'DAILY' ? (
      <Daily translationsData={chartDataDaily} translation={translationChart} />
    ) : (
      <Monthly translationsData={chartDataMonthly} translation={translationChart} />
    );

  return (
    <CustomContainer className="PrintOnly">
      <Title tag={'h2'} className={'TitleAll'} children={translationChart('title')} />
      <CardHelper
        title={translationChart('translatedWordsTitle')}
        icon={<YIcon name="signLanguage" />}
        noTopCardMargin={isMobile()}
        btnHelpContent={translationChart('translatedWordsContent')}
        btnHelpTopChildren={
          !isMobile() ? (
            <CustomSelect
              value={selectedLanguage}
              options={filterLanguages}
              onChange={(newValue) => setSelectedLanguage(newValue as Language)}
            />
          ) : (
            <></>
          )
        }
      >
        {isMobile() && (
          <CustomSelect
            value={selectedLanguage}
            onChange={(newValue) => setSelectedLanguage(newValue as Language)}
            options={filterLanguages}
          />
        )}
        <TabsContainer>
          <YTabs
            activeTabId={selectedChartTab}
            onTabChange={handleSelectTab}
            hasBorder={false}
          >
            {Array.isArray(chartTypeOptions) &&
              chartTypeOptions.map(({ id, titleKey }) => (
                <YTabPane
                  key={id}
                  id={id}
                  label={translationChart(titleKey)}
                  aria-label={`Aba ${translationChart(titleKey)}`}
                  title={translationChart(titleKey)}
                >
                  {getCurrentChart(id)}
                </YTabPane>
              ))}
          </YTabs>
        </TabsContainer>
      </CardHelper>
    </CustomContainer>
  );
};

export default Chart;
