import Auth from '../pages/Auth';
import AuthHandle from '../pages/AuthHandle';
import ClientDashboard from '../pages/ClientDashboard';
import DomainList from '../pages/DomainList';
import FirstAccess from '../pages/FirstAccess';
import NewPassword from '../pages/NewPassword';
import NotFound from '../pages/NotFound';
import RemoteConfigPage from '../pages/RemoteConfig';
import ResetPassword from '../pages/ResetPassword';
import Retrospective from '../pages/Retrospective';

export interface IPrimaryLayoutRoute {
  path: string;
  component: JSX.Element;
  onlyHeader?: boolean;
  private?: boolean;
}

export const PrimaryLayoutRoutes: IPrimaryLayoutRoute[] = [
  { path: '/', component: <Auth />, onlyHeader: true },
  { path: '/:accessKey', component: <ClientDashboard /> },
  // { path: '/remote-config/:accessKey', component: <RemoteConfigPage /> },
  { path: '/retrospective/:accessKey', component: <Retrospective />, onlyHeader: true },
  { path: '/not-found', component: <NotFound />, onlyHeader: true },
  { path: '/first-access', component: <FirstAccess />, onlyHeader: true },
  { path: '/domain-list', component: <DomainList />, onlyHeader: true },
  { path: '/reset-password', component: <ResetPassword />, onlyHeader: true },
  { path: '/new-password', component: <NewPassword />, onlyHeader: true },
  { path: '/auth-handle', component: <AuthHandle />, onlyHeader: true },
];
