import { YButton, YIcon } from '@hand-talk/yotta-components';
import CustomContainer from '../../../../components/CustomContainer';
import { useTranslation } from 'react-i18next';
import Timeline from '../../../../components/Timeline';
import { BtnContainer } from './styles';

interface TimeLineSlideProps {
  goBack: () => void;
}

export const TimeLineSlide = ({ goBack }: TimeLineSlideProps) => {
  const { t: translate } = useTranslation('SlidePage');

  return (
    <>
      <CustomContainer>
        <BtnContainer>
          <YButton
            onClick={() => goBack()}
            variation="text"
            withIcon={true}
            icon={<YIcon name="chevronLeft" />}
            iconPosition={'left'}
            title={translate('ButtonBackAlt')}
            aria-label={translate('ButtonBackAlt')}
          >
            {translate('ButtonBack')}
          </YButton>
        </BtnContainer>
        <Timeline />
      </CustomContainer>
    </>
  );
};
