/** Interfaces */
import { ICard } from '../../../../infra/services/interfaces';

/** Styles */
import { Section, TopCard } from '../styles';

const Card: React.FC<ICard> = ({ children, title, icon, className }) => {
  return (
    <Section className={className}>
      <TopCard className="AlignCenter">
        {icon}
        {title}
      </TopCard>
      {children}
    </Section>
  );
};

export default Card;
