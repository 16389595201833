// Libs
import { useTranslation } from 'react-i18next';

// Images
import LogoHTColored from '../../assets/svg/LogoHTColored';

// Styles
import { LogoWrapper, Title, Container, Subtitle } from './styles';

interface IHeaderFormProps {
  title?: string;
  success?: boolean;
  error?: boolean;
  subtitle?: string;
  maxWidth?: boolean;
}

export default function HeaderForm({
  title,
  subtitle,
  maxWidth = false,
  success = false,
  error = false,
}: IHeaderFormProps) {
  const { t: translationsHeaderForm } = useTranslation('HeaderForm');

  return (
    <Container>
      <LogoWrapper>
        <LogoHTColored ariaLabel={translationsHeaderForm('ImageAriaLabel')} />
      </LogoWrapper>
      {title && (
        <Title success={success} error={error}>
          {title}
        </Title>
      )}
      {subtitle && <Subtitle maxWidth={maxWidth}>{subtitle}</Subtitle>}
    </Container>
  );
}
