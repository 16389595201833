import { YIcon } from '@hand-talk/yotta-components';
import { useTranslation } from 'react-i18next';

/** Styles */
import { EmptyContainer } from './styles';

interface IEmptyContent {
  modalExpansion?: boolean;
}

export const EmptyContent: React.FC<IEmptyContent> = ({ modalExpansion }) => {
  const { t: translationUserTable } = useTranslation('Table');

  return (
    <EmptyContainer modalExpansion={modalExpansion}>
      <YIcon name="triangleAlert" />
      {modalExpansion ? (
        <span>{translationUserTable('modalExpansionLabel')}</span>
      ) : (
        <span>{translationUserTable('emptyLabel')}</span>
      )}
    </EmptyContainer>
  );
};
