/** Libs */
import React from 'react';
import { useTranslation } from 'react-i18next';

/** Hooks */
import usePreferencies from '../../hooks/usePreferencies';

/** Styles */
import { LoadingStyled, Text } from './styles';

/** Images */
import Loading from '../../assets/svg/Loading';
import i18n from '../../../infra/i18n';

interface ILoadingAnimated {
  isModal?: boolean;
}

const LoadingAnimated: React.FC<ILoadingAnimated> = ({ isModal }) => {
  const { t: translationsLoadingAnimated } = useTranslation('LoadingAnimated');
  const { oralLanguage } = usePreferencies();

  React.useEffect(() => {
    i18n.changeLanguage(oralLanguage);
  }, [oralLanguage]);

  return (
    <LoadingStyled isModal={isModal}>
      <Loading big />
      <Text role="status" aria-live="assertive">
        {translationsLoadingAnimated('loadingText')}
      </Text>
    </LoadingStyled>
  );
};

export default LoadingAnimated;
