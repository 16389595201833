/** Libs */
import { useTranslation } from 'react-i18next';
import { YIcon } from '@hand-talk/yotta-components';

/** Interfaces */
import { IAchievement } from '../../../infra/services/interfaces';

/** Styles */
import {
  BadgeContainer,
  BarContainer,
  ContentAchievement,
  ImageStyled,
  Marked,
  StyledBar,
  Text,
  Value,
} from './styles';

/** Tokens */
import { brand, neutral } from '../../assets/tokens/colors';

/** Services */
import { numberFormatter } from '../../../infra/services/helper';

/** Images */
import BadgeHundredThousand from '../../assets/svg/BadgeHundredThousand';
import BadgeAMillion from '../../assets/svg/BadgeAMillion';
import BadgeTenMillion from '../../assets/svg/BadgeTenMillion';
import BadgeOneHundredMillion from '../../assets/svg/BadgeOneHundredMillion';

/** Components */
import CustomContainer from '../CustomContainer';
import CardHelper from '../CardHelper';
import Title from '../Title';

type TAchievement = {
  translationsAccumulated: number;
  value: number;
  image?: JSX.Element;
  title?: string;
};

const Badge: React.FC<TAchievement> = ({
  translationsAccumulated,
  value,
  image,
}): JSX.Element => {
  return (
    <div
      className="FlexCenter"
      style={{
        filter: translationsAccumulated < value ? 'grayscale(100%)' : undefined,
        opacity: translationsAccumulated < value ? 0.4 : 1,
      }}
    >
      <ImageStyled>{image}</ImageStyled>
    </div>
  );
};

const Bar: React.FC<TAchievement> = ({
  translationsAccumulated,
  value,
  title,
}): JSX.Element => {
  return (
    <BarContainer>
      <p>{title}</p>
      <Marked
        style={{
          background:
            translationsAccumulated < value
              ? neutral.color.low.light
              : neutral.color.low.main,
        }}
      />
      <StyledBar
        style={{
          background:
            translationsAccumulated < value ? neutral.color.high.light : brand.color.main,
        }}
      />
    </BarContainer>
  );
};

const Achievement: React.FC<IAchievement> = ({ translationsAccumulated }) => {
  const { t: translateAchievement } = useTranslation('Achievement');

  return (
    <CustomContainer className="PrintOnly">
      <Title tag={'h2'} className={'TitleAll'} children={translateAchievement('title')} />
      <ContentAchievement>
        <CardHelper
          marginType={'customFooter'}
          title={translateAchievement('milestones')}
          icon={<YIcon name="throphy" />}
          btnHelpContent={translateAchievement('helpContent')}
        >
          <BadgeContainer>
            <Badge
              translationsAccumulated={translationsAccumulated}
              value={100000}
              image={<BadgeHundredThousand />}
            />
            <Badge
              translationsAccumulated={translationsAccumulated}
              value={1000000}
              image={<BadgeAMillion />}
            />
            <Badge
              translationsAccumulated={translationsAccumulated}
              value={10000000}
              image={<BadgeTenMillion />}
            />
            <Badge
              translationsAccumulated={translationsAccumulated}
              value={100000000}
              image={<BadgeOneHundredMillion />}
            />
          </BadgeContainer>
          <div className="AlignCenter">
            <Bar
              translationsAccumulated={translationsAccumulated}
              value={100000}
              title={'100K'}
            />
            <Bar
              translationsAccumulated={translationsAccumulated}
              value={1000000}
              title={'1M'}
            />
            <Bar
              translationsAccumulated={translationsAccumulated}
              value={10000000}
              title={'10M'}
            />
            <Bar
              translationsAccumulated={translationsAccumulated}
              value={100000000}
              title={'100M'}
            />
          </div>
          <CustomContainer className="FlexColumn ">
            <Text>{translateAchievement('wordsTranslated')}</Text>
            <Value>{numberFormatter(translationsAccumulated)}</Value>
          </CustomContainer>
        </CardHelper>
      </ContentAchievement>
    </CustomContainer>
  );
};

export default Achievement;
