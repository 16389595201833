import styled, { keyframes } from 'styled-components';

const scaleUp = keyframes`
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const Content = styled.section<{ size?: 'lg' | 'md' | 'sm' }>`
  max-width: ${({ size }) => (size === 'sm' ? '460px' : '1080px')};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface.bright.background[0]};
  border-radius: ${({ theme }) => theme.borderRadius.xlg};
  padding: ${({ theme }) => theme.spacing[6]};
  animation: ${scaleUp} 0.3s ease-out;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Footer = styled.div`
  margin-top: ${({ theme }) => theme.spacing[4]};
  border-top: 1px solid ${({ theme }) => theme.colors.border.low[0]};
  height: 72px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};
  font: ${({ theme }) => theme.typography.title.md};
`;
