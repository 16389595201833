import styled from 'styled-components';

export const Container = styled.div`
  min-height: calc(100vh - 60px);

  background-color: ${({ theme }) => theme.colors.primary.default[0]};

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 9px;
`;

export const ContentInitial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  gap: ${({ theme }) => theme.spacing[8]};
`;
