// Libs
import { inject, injectable } from 'inversify';

// Domain
import { RemoteConfigEntity } from '../../entities/RemoteConfigEntity';
import { RemoteConfigProvider } from '../../../infra/providers/RemoteConfigProvider';
import { IUserLogPayload } from '../../types/remoteConfig';
import { RemoteConfigSchema } from '../../schemas/remoteConfig/RemoteConfigSchema';

@injectable()
export default class SetRemoteConfigUseCase {
  @inject(RemoteConfigProvider) private configProvider: RemoteConfigProvider;

  public async execute(
    accessKey: string,
    remoteConfig: RemoteConfigSchema,
    userEmail: string,
    userLogInfo: IUserLogPayload
  ): Promise<RemoteConfigEntity | null> {
    if (!accessKey) {
      throw new Error('Token não informado!');
    }

    try {
      const configData = await this.configProvider.setConfig(
        accessKey,
        remoteConfig,
        userEmail,
        userLogInfo
      );
      return configData;
    } catch (error: any) {
      throw new Error(`Erro ao salvar configurações remotas: ${error.message as string}`);
    }
  }
}
