/** Library */
import React from 'react';
import i18n from '../../../../infra/i18n';

/** Services */
import { formatDashboardValue, formatDateRange } from '../../../../infra/services/helper';

/** Interfaces */
import { IReport } from '../../../../infra/services/interfaces';

/** Components */
import TopContainer from '../../../components/TopContainer';
import Overview from '../../../components/Overview';
import Chart from '../../../components/Chart';
import UserTable from '../../../components/UserTable';
import TopAddons from '../../../components/TopAddons';
import Achievement from '../../../components/Achievement';

/**
 * Renderiza o conteúdo do relatório único.
 */
const Report: React.FC<IReport> = ({
  report,
  isAverage,
  filter,
  handleExportCSV,
  handleExportPDF,
  userInfoState,
}) => {
  const {
    period,
    translations,
    translations: { translatedWords },
    insight: { topPages, topStates, topDevices, topAddons, users, addons },
    info: { domain, filterLanguages, token },
    achievements: { translatedWordsAccumulated },
  } = report;

  /** Manipulação do período */
  const periodFormattedFull = formatDateRange(period, i18n.language);

  const dataTopTopAddons = topAddons && topAddons.length > 0 ? topAddons : [];
  const dataTopDevices = topDevices && topDevices.length > 0 ? topDevices : [];
  const dataTopStates = topStates && topStates.length > 0 ? topStates : [];

  const data = {
    translations: formatDashboardValue(translatedWords).formatted,
    users: formatDashboardValue(users).formatted,
    addons: formatDashboardValue(addons && addons > 0 ? addons : 0).formatted,
  };

  return (
    <>
      <TopContainer
        onClickExportCsv={handleExportCSV}
        onClickExportPdf={handleExportPDF}
        filter={filter}
        domain={domain}
        addons={data.addons}
        report={report}
        userInfoState={userInfoState}
      />
      <Overview isAverageReport={isAverage} data={data} period={periodFormattedFull} />
      <Chart translations={translations} filterLanguages={filterLanguages} />
      <TopAddons data={dataTopTopAddons} period={periodFormattedFull} />
      <UserTable
        token={token}
        isAverage={isAverage}
        topStates={dataTopStates}
        topDevices={dataTopDevices}
        topPages={topPages}
        domain={domain}
        periodFormattedFull={periodFormattedFull}
        period={period}
      />
      <Achievement translationsAccumulated={translatedWordsAccumulated} />
    </>
  );
};

export default Report;
