import { SmallDataCardContainer, Text, Value } from './styles';

interface ISmallDataCardProps {
  text: string;
  value: string;
  className?: string;
}

const SmallDataCard = ({ text, value, className }: ISmallDataCardProps) => {
  return (
    <SmallDataCardContainer className={className}>
      <Text>{text}</Text>
      <Value>{value}</Value>
    </SmallDataCardContainer>
  );
};

export default SmallDataCard;
