/** Libs */
import styled from 'styled-components';

export const LoadingStyled = styled.div<{ isModal?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: ${({ isModal }) => (isModal ? '382px' : 'auto')};
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const Text = styled.p`
  font: ${({ theme }) => theme.typography.label.mdBold};
  color: ${({ theme }) => theme.colors.primary.default[0]};
`;
