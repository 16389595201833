/**
 * Recupera um item do localStorage.
 */
export const getStoredItem = <T>(key: string): T | null => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

/**
 * Salva um item no localStorage.
 */
export const setStoredItem = <T>(key: string, item: T): void => {
  localStorage.setItem(key, JSON.stringify(item));
};
