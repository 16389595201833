import { IHelpProps } from '@hand-talk/yotta-components/dist/components/YHelpButton/interfaces';
import { UserInfoState } from '../../view/hooks/useUserInfo';

export enum Language {
  'en-ase' = 'en-ase',
  'ptBR-bzs' = 'ptBR-bzs',
}

export interface IDomain {
  /** URL do site com o HTTPS */
  value: string;
  /** URL do site sem o HTTPS */
  name: string;
}

export type CsvLabelKeys =
  | 'csvDiary'
  | 'csvCumulative'
  | 'csvTitle'
  | 'csvTopPages'
  | 'csvTopStates'
  | 'csvTopDevices'
  | 'csvTopAddons';

export interface ICsvLabels extends Record<CsvLabelKeys, string> {}

export type ITBody = {
  domain: IDomain;
  period: string;
  top10PagesData: Record<string, any>[];
};

export interface IUrlLinkProps {
  domain: string;
  route: string;
}

export interface IAchievement {
  /** Traduções do inicio da assinatura, até mês anterior de geração */
  translationsAccumulated: number;
}

export interface IReport {
  report: IReportSchema;
  filter: JSX.Element;
  isAverage: boolean;
  handleExportCSV?: () => void;
  handleExportPDF: () => void;
  userInfoState: UserInfoState;
  translationsAccumulatedFormatted: string;
}

export interface ITopContainer {
  domain: string;
  filter: JSX.Element;
  addons: string;
  report: IReportSchema | IAverageReportSchema;
  userInfoState: UserInfoState;
  onClickExportCsv?: () => void;
  onClickExportPdf?: () => void;
}

export interface IOverview {
  period: string;
  data: {
    translations: string;
    users: string;
    addons: string;
  };
  isAverageReport: boolean;
}

export interface ICard {
  children: React.ReactNode;
  title: JSX.Element;
  icon: JSX.Element;
  className?: string;
}

export interface IDataCard {
  small?: boolean;
}

export interface IButtonHelper extends Partial<IHelpProps> {
  btnHelpTopChildren?: React.ReactNode;
  btnHelpBottomChildren?: React.ReactNode;
  btnHelpContent: string;
  btnHelpClassName?: string;
}

export interface ICardHelper extends Partial<IButtonHelper> {
  /** Conteúdo a ser exibido dentro do CardHelper */
  children: React.ReactNode;
  /** Título do CardHelper, caso tenha */
  title?: string;
  /** Header personalizado, caso tenha (substituirá o título) */
  customHeader?: JSX.Element;
  /** Ícone a ser exibido no CardHelper */
  icon?: JSX.Element;
  /** Botão de ajuda a ser exibido no CardHelper */
  buttonHelp?: JSX.Element;
  /** Define se o TopCard terá margem ou não (opcional) */
  noTopCardMargin?: boolean;
  /** Define se o CardHelper terá margem ou não (opcional) */
  marginType?: 'none' | 'customFooter';
  /* Permite a adição de uma estilização personalizada (opcional) */
  className?: string;
}

export interface ITitle {
  children: React.ReactNode;
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className: string;
}

export interface IGraphics {
  translationsData: any[];
}

type TFeedbackType = 'positive' | 'info' | 'alert' | 'error';

export interface IFeedbackCard {
  type: TFeedbackType;
  /** Título do feedback */
  titleText: string;
  /** Texto do feedback */
  text: string;
  /** Ícone a ser exibido no feedback (JSX.Element) */
  icon: JSX.Element;
  /** Função para fechar o feedback */
  onClose?: () => void;
  /** Indica se o feedback está aberto (visível) */
  isOpen: boolean;
  /** URL do link opcional relacionado ao feedback */
  link?: string;
  /** Texto a ser exibido no link opcional */
  labelLink?: string;
}

export interface ILoadingWrapper {
  loading?: boolean;
  children: React.ReactNode;
}

export type ReportSource = 'ga4';

export interface IPeriod {
  startDate: string;
  endDate: string;
}

export interface IInfo {
  /** Dominio da assinatura */
  domain: string;
  /** Token da assinatura */
  token: string;
  /** Língua de origem do relatório */
  language: Language;
  /** Data de criação da assinatura */
  subscriptionCreationDate: string;
  /** Línguas disponiveis para filtragem */
  filterLanguages: Language[];
}

export interface IAchievements {
  translatedWordsAccumulated: number;
}

export interface ITableItem {
  /** Métrica */
  source: string;
  /** Número de pessoas impactadas */
  value: number;
}

export interface IChartData {
  day: string;
  number: number;
}

export interface IChart {
  translations: ITranslationHistory;
  filterLanguages: Language[];
}

export interface ICardChart {
  children: React.ReactNode;
  title: string;
  icon: JSX.Element;
  buttonHelp: JSX.Element;
  smallDataCard: JSX.Element;
  noTopCardMargin?: boolean;
  marginType?: 'none' | 'customFooter';
  className?: string;
}

export interface IInsight {
  /** Período do relatório */
  period: IPeriod;
  /** Origem do relatório */
  source: ReportSource;
  /** Pessoas impactadas por página */
  topPages: ITableItem[];
  /** Pessoas impactadas por estado */
  topStates?: ITableItem[];
  /** Pessoas impactadas por dispositivo */
  topDevices?: ITableItem[];
  /** Pessoas impactadas pelos complementos */
  topAddons?: ITableItem[];
  /** Quantidade de ativações dos complementos */
  addons?: number;
  /** Pessoas impactadas */
  users: number;
}

export interface IAccessKey {
  /** Data de expiração da última chave de acesso */
  expiresIn: string;
  /** Última chave de acesso gerada para este relátorio */
  value: string;
}

export interface ISignLanguageHistory {
  /** Lista de tradução por dia */
  dailyInfo: Record<string, number>;
}

export interface ITranslationHistory {
  signLanguage: Partial<Record<Language, ISignLanguageHistory>>;
  translatedWords: number;
}

export interface IReportSchema {
  source: ReportSource;
  period: IPeriod;
  info: IInfo;
  achievements: IAchievements;
  translations: ITranslationHistory;
  accessKey?: IAccessKey;
  insight: IInsight;
}

export type Report = IReportSchema;

export interface IAverageInsight {
  period: IPeriod;
  averageTopDevices?: ITableItem[];
  averageTopStates?: ITableItem[];
  averageTopPages: ITableItem[];
  topAddons?: ITableItem[];
  addons?: number;
  averageUsers: number;
  monthlyUsersInfo: Record<string, number>;
}

export interface IAverageReportSchema {
  period: IPeriod;
  info: IInfo;
  achievements: IAchievements;
  translationHistory: ITranslationHistory;
  averageInsight: IAverageInsight;
}

export type AverageReport = IAverageReportSchema;

/** Tipos de fontes de dados que podem ser filtradas */
export type SourceType = 'region' | 'landingPagePlusQueryString' | 'deviceCategory';

export interface IReportInsight {
  data: ITableItem[];
  totalPages: number;
}

export interface IFilterProperties {
  limit: number;
  currentPage?: number;
  filterContent?: string;
}
