import styled from 'styled-components';

/** Services */
import {
  mobileMediaQuery,
  smallMobileMediaQuery,
  tabletMediaQuery,
} from '../../../../../infra/services/helper';

export const Container = styled.div<{ isPrintActive?: boolean }>`
  min-height: ${({ isPrintActive }) => (isPrintActive ? '100vh' : 'calc(100vh - 60px)')};
  width: 100%;

  overflow: hidden !important;
`;

/** Slide Pages */

export const ContainerSlide = styled.div<{ currentPage: number }>`
  min-height: calc(100vh - 60px);

  background: ${({ theme, currentPage }) =>
    currentPage === 0
      ? 'linear-gradient(90deg, #C64F01 0%, #7F380F 100%)'
      : currentPage === 1
      ? theme.colors.secondary.default[0]
      : currentPage === 2
      ? theme.colors.tertiary.default[0]
      : currentPage === 3
      ? theme.colors.positive.default[0]
      : currentPage === 4
      ? theme.colors.primary.default[0]
      : theme.colors.primary.onLow[0]};

  display: flex;
  justify-content: center;
`;

export const ContentSlide = styled.div`
  width: 1280px;
  min-height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  .previous_btn {
    position: absolute;
    top: calc(50vh);
    left: 0;

    ${({ theme }) =>
      mobileMediaQuery(`
      left: ${theme.spacing[3]};
    `)};
  }

  .next_btn {
    position: absolute;
    top: calc(50vh);
    right: 0;

    ${({ theme }) =>
      mobileMediaQuery(`
      right: ${theme.spacing[3]};
    `)};
  }

  .previous_btn,
  .next_btn {
    background-color: ${({ theme }) => theme.colors.surface.bright.background[0]};

    svg path {
      fill: ${({ theme }) => theme.colors.primary.default[0]};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.onLow[0]};
    }

    z-index: 10;

    ${smallMobileMediaQuery(`
      bottom: 0;
      top: 350px;  
    `)}
  }

  ${({ theme }) =>
    tabletMediaQuery(`
      width: 1239px;
      padding: 0 ${theme.spacing[7]};
  `)};

  ${({ theme }) =>
    mobileMediaQuery(`
      width: 600px;
      padding: 0 ${theme.spacing[7]};
  `)};

  ${({ theme }) =>
    smallMobileMediaQuery(`
      width: 100%;
      padding: 0 ${theme.spacing[4]};
  `)}
`;

export const SlideContent = styled.div`
  max-width: 1280px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
