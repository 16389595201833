import React, { useEffect, useState } from 'react';

/** Components */
import SlidePage from './components/RetrospectiveSlidePage';
import Footer from '../../components/Footer';
import LoadingWrapper from '../LoadingWrapper';

/** Hooks */
import useNavigation from '../../hooks/useNavigation';
import useReport from '../../hooks/useReport';
import usePreferencies from '../../hooks/usePreferencies';
import { DefaultSlide } from './pages/defaultSlide';
import { TimeLineSlide } from './pages/timelineSlide';
import { isRouteValid, numberFormatter } from '../../../infra/services/helper';
import { ITableItem } from '../../../infra/services/interfaces';
import { RetroFormattedData } from './types';
import { Translations } from '../../../infra/services/TranslationsHelper';
import { handleDownloadRetroPdf } from './components/pdfGenerate';
import { useTranslation } from 'react-i18next';

const Retrospective: React.FC = () => {
  const { t: translate, i18n } = useTranslation('SlidePage');
  const { currentPage, nextPage, previousPage, lastDirection } = useNavigation(6);
  const [isPrintActive, setIsPrintActive] = useState(false);
  const { language, accessKey } = usePreferencies();
  const { reportYearly, getYearlyReport, isLoading } = useReport();
  const [formattedData, setFormattedData] = useState<RetroFormattedData>({
    topPages: [],
    topAddons: [],
    translatedWords: '0',
    users: '0',
  });

  const validatePageToShow = () => {
    let changePage: boolean = false;

    if (currentPage === 1 && !formattedData.translatedWords) changePage = true;
    else if (currentPage === 2 && !formattedData.users) changePage = true;
    else if (currentPage === 3 && !formattedData.topPages.length) changePage = true;
    else if (currentPage === 4 && !formattedData.topAddons.length) changePage = true;

    if (changePage) {
      lastDirection === 'next' ? nextPage() : previousPage();
    }
  };

  useEffect(() => {
    if (currentPage) {
      validatePageToShow();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!accessKey || !language) return;

    getYearlyReport(accessKey, language);
  }, [accessKey]);

  const formatData = () => {
    let pageList: ITableItem[] = (reportYearly!.insight.topPages ?? []).slice(0, 3);
    pageList[0] = { source: '(not set)', value: 0 };

    const domain = reportYearly!.info.domain;

    const topPages = pageList.map((row, idx) =>
      isRouteValid(domain, row?.source)
        ? { value: `${idx + 1}º ${domain}${row.source}` }
        : { value: `${idx + 1}º ${domain}/` }
    );

    const topAddons = reportYearly!.insight.topAddons as ITableItem[];

    const formattedTopAddons =
      topAddons && topAddons.length
        ? i18n.language == 'en'
          ? [
              { value: `1º ${Translations['en'].topAddons[topAddons![0]?.source]}` },
              { value: `2º ${Translations['en'].topAddons[topAddons![1]?.source]}` },
              { value: `3º ${Translations['en'].topAddons[topAddons![2]?.source]}` },
            ]
          : [
              { value: `1º ${topAddons![0]?.source}` },
              { value: `2º ${topAddons![1]?.source}` },
              { value: `3º ${topAddons![2]?.source}` },
            ]
        : [];

    setFormattedData({
      topPages,
      topAddons: formattedTopAddons,
      translatedWords: numberFormatter(
        reportYearly?.translations.translatedWords as number
      ),
      users: numberFormatter(reportYearly?.insight.users as number) || 0,
    });
  };

  useEffect(() => {
    if (reportYearly) {
      formatData();
    }
  }, [reportYearly, i18n.language]);

  const handlePrintPdf = () => {
    handleDownloadRetroPdf(translate('AltRetrospectiveImage'), formattedData);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      {currentPage === -1 && <DefaultSlide goToNext={nextPage} />}
      {![-1, 6].includes(currentPage) && (
        <SlidePage
          handleExport={() => handlePrintPdf()}
          currentPage={currentPage}
          nextPage={nextPage}
          previousPage={previousPage}
          formattedData={formattedData}
        />
      )}
      {currentPage === 6 && <TimeLineSlide goBack={previousPage} />}
      <Footer />
    </LoadingWrapper>
  );
};

export default Retrospective;
