/** Libs */
import React from 'react';
import { startOfMonth as startOfMonthFns, addMonths, subMonths } from 'date-fns';

/** Types */
import { Timestamp } from '../../../../infra/services/Timestamp';

/**
 * Retorna o início do mês com base no timestamp fornecido
 */
const startOfMonth = (monthTimestamp: Timestamp): Timestamp => {
  const date = new Date(monthTimestamp);
  const startOfMonthDate = startOfMonthFns(date);
  return startOfMonthDate.getTime() as Timestamp;
};

/**
 * Retorna o timestamp do próximo mês com base no timestamp fornecido
 */
const getNextMonthTimestamp = (monthTimestamp: Timestamp): Timestamp => {
  const currentDate = new Date(monthTimestamp);
  const nextDate = addMonths(currentDate, 1);
  return startOfMonth(nextDate.getTime() as Timestamp);
};

/**
 * Retorna o timestamp do mês anterior com base no timestamp fornecido
 */
const getPrevMonthTimestamp = (monthTimestamp: Timestamp): Timestamp => {
  const currentDate = new Date(monthTimestamp);
  const prevDate = subMonths(currentDate, 1);
  return startOfMonth(prevDate.getTime() as Timestamp);
};

export const useCalendarMonth = (timestamp: Timestamp) => {
  /** Calcula o timestamp do primeiro dia do mês */
  const monthTimestamp = React.useMemo(() => startOfMonth(timestamp), [timestamp]);

  /** Armazena o timestamp do mês atual */
  const [currentMonthTimestamp, setCurrentMonthTimestamp] =
    React.useState(monthTimestamp);

  const onPrevMonth = React.useCallback(() => {
    const prevMonthTimestamp = getPrevMonthTimestamp(currentMonthTimestamp);
    setCurrentMonthTimestamp(prevMonthTimestamp);
  }, [currentMonthTimestamp]);

  const onNextMonth = React.useCallback(() => {
    const nextMonthTimestamp = getNextMonthTimestamp(currentMonthTimestamp);
    setCurrentMonthTimestamp(nextMonthTimestamp);
  }, [currentMonthTimestamp]);

  React.useEffect(() => {
    setCurrentMonthTimestamp(monthTimestamp);
  }, [monthTimestamp]);

  return {
    currentMonthTimestamp,
    prevMonthTimestamp: getPrevMonthTimestamp(currentMonthTimestamp),
    nextMonthTimestamp: getNextMonthTimestamp(currentMonthTimestamp),
    onPrevMonth,
    onNextMonth,
  };
};
