/** Libs */
import React from 'react';
import { useTranslation } from 'react-i18next';

/** Services */
import usePreferencies from '../../../hooks/usePreferencies';

/** Styles */
import { Container, ContainerMonth } from './styles';

/** Components */
import { YIcon, YIconButton } from '@hand-talk/yotta-components';

const locales: Record<string, string> = {
  pt: 'pt-br',
  en: 'en-us',
};

interface ICalendarHeader {
  monthTimestamp: number;
  isDisabled?: boolean;
  onPrevMonth?: () => void;
  onNextMonth?: () => void;
}

const CalendarHeader: React.FC<ICalendarHeader> = (props) => {
  const { isDisabled, monthTimestamp, onPrevMonth, onNextMonth } = props;
  const { oralLanguage } = usePreferencies();

  const { t: translateYCalendar } = useTranslation('YCalendar');

  const date = new Date(monthTimestamp);
  const month = date.toLocaleDateString(locales[oralLanguage], {
    month: 'long',
  });

  const handlePrevMonth = React.useCallback((): void => {
    if (isDisabled || !onPrevMonth) {
      return;
    }

    onPrevMonth();
  }, [isDisabled, onPrevMonth]);

  const handleNextMonth = React.useCallback((): void => {
    if (isDisabled || !onNextMonth) {
      return;
    }

    onNextMonth();
  }, [isDisabled, onNextMonth]);

  return (
    <Container>
      {onPrevMonth && (
        <YIconButton
          data-mode={translateYCalendar('Previous')}
          aria-label={translateYCalendar('Previous')}
          title={translateYCalendar('Previous')}
          onClick={handlePrevMonth}
        >
          <YIcon name="chevronLeft" />
        </YIconButton>
      )}
      <ContainerMonth>
        {month[0].toUpperCase() + month.substr(1)}
        <span>{date.getFullYear()}</span>
      </ContainerMonth>
      {onNextMonth && (
        <YIconButton
          data-mode={translateYCalendar('Next')}
          aria-label={translateYCalendar('Next')}
          title={translateYCalendar('Next')}
          onClick={handleNextMonth}
        >
          <YIcon name="chevronRight" />
        </YIconButton>
      )}
    </Container>
  );
};

const CalendarHeaderMemo = React.memo(CalendarHeader);
export { CalendarHeaderMemo as CalendarHeader };
