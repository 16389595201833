/** Libs */
import styled from 'styled-components';

/** Services */
import { mobileMediaQuery, tabletMediaQuery } from '../../../infra/services/helper';

export const FooterContainer = styled.footer`
  width: 100%;
`;

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.dim[1][0]};
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};
  text-align: center;

  .VerticalLine {
    width: 1px;
    height: 80px;
    background-color: ${({ theme }) => theme.colors.border.low[0]};

    ${tabletMediaQuery(`
      height: 1px;
      width: 130.12px;
      margin: 28px 0;
    `)}
  }
`;

export const StyledContent = styled.div`
  padding-block: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${tabletMediaQuery(`
    padding: 78px 0;
    flex-direction: column-reverse;
  `)}

  ${mobileMediaQuery(`
    padding: 62.5px 0;
  `)}
`;

export const Copyright = styled.p`
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Lato';

  a {
    font-weight: 800;
  }

  .date {
    margin-right: 4px;
  }
`;

export const Title = styled.p`
  font: ${({ theme }) => theme.typography.title.sm};
  font-weight: 900;
  margin-bottom: 8px;
`;

export const Social = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

export const SupportGuide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mobileMediaQuery(`
    flex-direction: column;
  `)}
`;

export const Support = styled.div`
  max-width: 232px;
  width: 100%;
  margin-right: 60px;

  ${mobileMediaQuery(`
    margin: 0 0 28px 0;
    max-width: 100%;
  `)}
`;

export const Guide = styled.div`
  max-width: 320px;
  width: 100%;

  ${mobileMediaQuery(`
    max-width: 100%;
  `)}
`;
