import React from 'react';

const useNavigation = (totalPages: number) => {
  /** -1 indica a página inicial, antes da navegação */
  const [currentPage, setCurrentPage] = React.useState(-1);
  const [lastDirection, setLastDirection] = React.useState<'previous' | 'next'>('next');

  /** Renderiza para a próxima página, limitando ao total de páginas */
  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    setLastDirection('next');
  };

  /** Renderiza para a página anterior, limitando a -1 */
  const previousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, -1));
    setLastDirection('previous');
  };

  /** Renderiza para uma página específica */
  const goToPage = (page: number) =>
    setCurrentPage(Math.min(Math.max(page, -1), totalPages));

  return { currentPage, nextPage, previousPage, goToPage, lastDirection };
};

export default useNavigation;
