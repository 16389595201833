/** Libs */
import styled from 'styled-components';

/** Tokens */
import { neutral } from '../../../assets/tokens/colors';
import { mobileMediaQuery } from '../../../../infra/services/helper';

export const WrapperCalendarDay = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 100%;

  &[data-highlighted-mode] {
    background-color: ${({ theme }) => theme.colors.secondary.container[0]};
    color: ${({ theme }) => theme.colors.secondary.onContainer[0]};
    border-radius: 0;
  }

  &[data-highlighted-mode='to'] {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  &[data-highlighted-mode='from'] {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  &[data-highlighted-mode='middle'] {
    button[data-current-month='false'] {
      color: ${neutral.color.low.main};
    }
  }

  ${mobileMediaQuery(`
      width: 40px;
      height: 40px;
  `)}
`;

export const ButtonCalendar = styled.button`
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  font: ${({ theme }) => theme.typography.label.sm};
  border: 4px solid ${({ theme }) => theme.colors.surface.bright.background[0]};

  &[data-highlighted-mode] {
    border: 4px solid transparent;
  }

  &:hover:not(:disabled),
  &:focus-visible {
    color: ${neutral.color.low.main};

    &[data-selected='true'] {
      color: ${neutral.color.high.main};
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: ${neutral.color.high.medium};
  }

  &[data-current-month='false'] {
    color: ${neutral.color.high.medium};
  }

  &[data-selected='true'] {
    background-color: ${({ theme }) => theme.colors.secondary.default[0]};
    color: ${({ theme }) => theme.colors.secondary.on[0]};
  }
`;
