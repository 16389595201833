import styled from 'styled-components';
import {
  mobileMediaQuery,
  smallMobileMediaQuery,
} from '../../../../../infra/services/helper';

export const Container = styled.div<{ isPrintActive?: boolean }>`
  min-height: calc(100vh - 60px);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[8]};

  padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[12]}`};

  ${({ isPrintActive }) =>
    isPrintActive &&
    `
    width: 100%;
    
    flex-direction: column;
  `};

  ${mobileMediaQuery(`
    flex-direction: column;

    padding-left: 0;
    padding-right: 0;
  `)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[7]};
`;

export const ContentImage = styled.div`
  ${smallMobileMediaQuery(`
  `)}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font: ${({ theme }) => theme.typography.display.lg};
    font-size: ${({ theme }) => theme.font.size['09']};
    color: ${({ theme }) => theme.colors.primary.onLow[0]};
    line-height: 130%;

    ${({ theme }) =>
      mobileMediaQuery(`
      font-size: ${theme.font.size['08']};
    `)};

    ${({ theme }) =>
      smallMobileMediaQuery(`
      font-size: ${theme.font.size['06']};
    `)};
  }
`;

export const Title = styled.h3`
  font-weight: 300 !important;
`;

export const Subtitle = styled.h3`
  font-family: ${({ theme }) => theme.font.family.primary} !important;
  font-style: italic !important;
`;

export const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    font-size: ${({ theme }) => theme.font.size['05']};
    line-height: 150%;

    color: ${({ theme }) => theme.colors.secondary.on[0]};
    text-align: start;

    strong {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary.onLow[0]};
      text-align: start;
    }

    ${({ theme }) =>
      mobileMediaQuery(`
      font-size: ${theme.font.size['04']};
    `)};

    ${({ theme }) =>
      smallMobileMediaQuery(`
      font-size: ${theme.font.size['03']};
    `)};
  }
`;
