// Libs
import { inject, injectable } from 'inversify';
import { jwtDecode } from 'jwt-decode';
import {
  User,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
  inMemoryPersistence,
  onAuthStateChanged,
  updatePassword,
  signInWithEmailLink,
  isSignInWithEmailLink,
  confirmPasswordReset,
  checkActionCode,
} from 'firebase/auth';

// Dependencies
import FirebaseApi from '../apis/FirebaseApi';
import DashboardApi from '../apis/DashboardApi';

export interface IUserTokenData {
  uid: string;
  email: string;
  displayName: string;
  aud: string;
  exp: number;
}

@injectable()
export default class UserRepository {
  @inject(FirebaseApi) firebaseApi: FirebaseApi;
  @inject(DashboardApi) dashboardApi: DashboardApi;

  public get user() {
    return this.firebaseApi.auth.currentUser;
  }

  public addAuthChangeListener = (callback: (user: User | null) => void) => {
    const { auth } = this.firebaseApi;
    return onAuthStateChanged(auth, callback);
  };

  public signIn = async (email: string, password: string): Promise<User> => {
    const { auth } = this.firebaseApi;
    await setPersistence(auth, browserLocalPersistence);
    const result = await signInWithEmailAndPassword(auth, email, password);
    return result.user;
  };

  public signInWithEmailLink = async (
    email: string,
    emailLink: string
  ): Promise<User> => {
    const { auth } = this.firebaseApi;

    await setPersistence(auth, inMemoryPersistence);

    if (!isSignInWithEmailLink(auth, emailLink)) {
      throw new Error('Acesso negado');
    }

    const result = await signInWithEmailLink(auth, email, emailLink);
    return result.user;
  };

  public signOut = async () => {
    const { auth } = this.firebaseApi;
    return signOut(auth);
  };

  public updatePassword = async (password: string) => {
    const { auth } = this.firebaseApi;

    if (!this.user) {
      throw new Error('Usuário não está logado!');
    }

    await setPersistence(auth, browserLocalPersistence);
    await updatePassword(this.user, password);
  };

  public resetPassword = async (email: string): Promise<void> => {
    await this.dashboardApi.post('resetUserPassword', {
      email,
    });
  };

  public decodeToken(token: string) {
    const content = jwtDecode(token) as IUserTokenData;

    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (!content.exp) {
      throw new Error('Código de acesso inválido!');
    }

    if (currentTimestamp >= content.exp) {
      throw new Error('Código de acesso expirado!');
    }

    return content;
  }

  public isValidOobCode = async (oobCode: string) => {
    const { auth } = this.firebaseApi;
    return await checkActionCode(auth, oobCode);
  };

  public confirmResetPassword = async (
    oobCode: string,
    newPassword: string
  ): Promise<void> => {
    const { auth } = this.firebaseApi;
    await confirmPasswordReset(auth, oobCode, newPassword);
  };
}
