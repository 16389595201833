// Libs
import { useCallback, useState } from 'react';
import { useInjection } from 'inversify-react';

// Services
import UserRepository from '../../infra/repositories/UserRepository';

interface IOobCodeValidationState {
  type: 'loading' | 'error' | 'success';
}

type OobCodeValidationState = IOobCodeValidationState;

export default function useOobCodeValidation() {
  const userRepository = useInjection(UserRepository);
  const [oobCodeValidationState, setAuthState] = useState<OobCodeValidationState>({
    type: 'loading',
  });

  const validate = useCallback(
    async (oobCode?: string | null) => {
      if (!oobCode) {
        setAuthState({ type: 'error' });
        return;
      }

      try {
        setAuthState({ type: 'loading' });
        const result = await userRepository.isValidOobCode(oobCode);
        if (result) {
          setAuthState({ type: 'success' });
        } else {
          setAuthState({ type: 'error' });
        }
      } catch (error) {
        setAuthState({ type: 'error' });
      }
    },
    [userRepository]
  );

  return { validate, oobCodeValidationState };
}
