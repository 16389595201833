// Libs
import { useTranslation } from 'react-i18next';

// Services
import { IPeriod, ITableItem } from '../../../infra/services/interfaces';

// Components
import CustomContainer from '../CustomContainer';
import Title from '../Title';
import TopStates from './TopStates';
import TopDevices from './TopDevices';
import TopPages from './TopPages';

// Styles
import { Container } from './styles';

export interface IUserChart {
  topStates: ITableItem[];
  topDevices: ITableItem[];
  topPages: ITableItem[];
  domain: string;
  isAverage: boolean;
  token: string;
  periodFormattedFull: string;
  period: IPeriod;
}

const UserTable: React.FC<IUserChart> = ({
  topStates,
  topDevices,
  topPages,
  domain,
  isAverage,
  token,
  periodFormattedFull,
  period,
}) => {
  const { t: translationUserTable } = useTranslation('UserTable');

  return (
    <CustomContainer className="PrintOnly">
      {!isAverage && (
        <Title
          tag={'h2'}
          className={'TitleAll'}
          children={translationUserTable('titleAnalysis')}
        />
      )}
      <Container>
        <TopStates
          token={token}
          period={period}
          isAverage={isAverage}
          topStates={topStates}
        />
        <TopDevices isAverage={isAverage} topDevices={topDevices} />
      </Container>
      <TopPages
        topPages={topPages}
        periodFormattedFull={periodFormattedFull}
        domain={{
          name: domain,
          value: `https://${domain}/`,
        }}
        isAverage={isAverage}
      />
    </CustomContainer>
  );
};

export default UserTable;
