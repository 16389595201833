// Libs
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { inject, injectable } from 'inversify';
import { getFunctions } from 'firebase/functions';

// Dependencies
import Config from './Config';

@injectable()
export default class FirebaseApi {
  @inject(Config) config: Config;
  private _app: FirebaseApp | null;

  public get app() {
    if (!this._app) {
      this._app = this._app = initializeApp({
        apiKey: this.config.firebaseApiKey,
        projectId: this.config.firebaseProjectId,
      });
    }
    return this._app;
  }

  public get functions() {
    return getFunctions(this.app);
  }

  public get auth() {
    return getAuth(this.app);
  }
}
