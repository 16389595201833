import styled from 'styled-components';
import {
  mobileMediaQuery,
  smallMobileMediaQuery,
} from '../../../../../infra/services/helper';

export const Container = styled.div<{ isPrintActive?: boolean }>`
  min-height: calc(100vh - 60px);

  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.spacing[6]};
  padding-bottom: 0;

  ${({ isPrintActive }) =>
    isPrintActive &&
    `
    width: 100%;
    
    flex-direction: column;
    justify-content: space-between;
  `};

  ${mobileMediaQuery(`
    flex-direction: column;
    justify-content: space-between;

    padding-inline: 0;
  `)};
`;

export const ImageLeftContainer = styled.div`
  img {
    max-width: 300px;
  }

  ${mobileMediaQuery(`
    display: none;
  `)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[7]};
  align-items: center;
`;

export const Header = styled.h3`
  font: ${({ theme }) => theme.typography.display.lg};
  font-size: ${({ theme }) => theme.font.size['09']};
  font-weight: 900;
  color: ${({ theme }) => theme.colors.secondary.onLow[0]};

  text-align: center;
  line-height: 100%;

  ${({ theme }) =>
    mobileMediaQuery(`
    font-size: ${theme.font.size['09']};
  `)};

  ${({ theme }) =>
    smallMobileMediaQuery(`
    font-size: ${theme.font.size['05']};
  `)};
`;

export const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  strong {
    font-size: ${({ theme }) => theme.font.size['10']};
    font-weight: 900;
    color: ${({ theme }) => theme.colors.secondary.on[0]};

    padding: 24px;
  }

  p {
    font-size: ${({ theme }) => theme.font.size['07']};
    font-weight: 900;
    line-height: 100%;
    font-style: italic;
    color: ${({ theme }) => theme.colors.secondary.onLow[0]};

    ${({ theme }) =>
      mobileMediaQuery(`
      font-size: ${theme.font.size['06']};
    `)};

    ${({ theme }) =>
      smallMobileMediaQuery(`
      font-size: ${theme.font.size['04']};
    `)};
  }
`;

export const Footer = styled.div`
  max-width: 440px;

  p {
    font-size: ${({ theme }) => theme.font.size['05']};
    font-weight: 400;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.secondary.on[0]};

    text-align: center;

    strong {
      font-weight: 900;
    }

    ${({ theme }) =>
      mobileMediaQuery(`
      font-size: ${theme.font.size['04']};
    `)};

    ${({ theme }) =>
      smallMobileMediaQuery(`
      font-size: ${theme.font.size['03']};
    `)};
  }

  ${smallMobileMediaQuery(`
    max-width: 240px;
  `)};
`;

export const ImageRightContainer = styled.div`
  display: flex;

  height: 600px;

  ${mobileMediaQuery(`
    height: fit-content;
  `)}

  ${smallMobileMediaQuery(`
    img {
      max-width: 100%;
    }
  `)};
`;
