/** Libs */
import styled from 'styled-components';

export const IconWrapper = styled.span`
  display: inline-flex;
  vertical-align: middle;
  margin-right: 8px;
  svg {
    height: 20px;
    width: auto;
  }
`;
