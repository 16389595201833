import { YHelpButton } from '@hand-talk/yotta-components';
import { isMobile } from '../../../infra/services/helper';
import { IButtonHelper } from '../../../infra/services/interfaces';

const ButtonHelperContainer: React.FC<IButtonHelper> = ({
  btnHelpContent,
  context = 'dark',
  display = 'desktop',
  alignment = 'center',
  direction = isMobile() === true ? 'left' : 'bottom',
  btnHelpClassName,
  btnHelpTopChildren,
  btnHelpBottomChildren,
}) => {
  return (
    <div className={btnHelpClassName || 'AlignCenter'}>
      {btnHelpTopChildren}
      <YHelpButton
        {...{
          content: btnHelpContent,
          context,
          display,
          alignment,
          direction,
        }}
      />
      {btnHelpBottomChildren}
    </div>
  );
};

export default ButtonHelperContainer;
