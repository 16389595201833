/** Libs */
import React from 'react';
import { YIcon, YIconButton } from '@hand-talk/yotta-components';

/** Styles */
import { Container, ContainerSlide, ContentSlide } from './styles';

// import { printPDF } from '../../../infra/services/helper';
import { WelcomeSlide } from '../../pages/welcomeSlide';
import { WordsSlide } from '../../pages/wordsSlide';
import { UsersSlide } from '../../pages/usersSlide';
import { PagesSlide } from '../../pages/pagesSlide';
import { TopAddonsSlide } from '../../pages/topAddonsSlide';
import { ShareResultSlide } from '../../pages/shareResultSlide';
import { RetroFormattedData } from '../../types';
import { useTranslation } from 'react-i18next';

interface ISlide {
  handleChangePage: (next?: boolean) => void;
  showNextButton?: boolean;
  showBackButton?: boolean;
  children?: React.ReactNode;
  currentPage: number;
}

interface ISlidePage {
  currentPage: number;
  nextPage: () => void;
  previousPage: () => void;
  handleExport: () => void;
  isPrintActive?: boolean;
  formattedData: RetroFormattedData;
}

const Slide: React.FC<ISlide> = ({
  handleChangePage,
  showNextButton = true,
  showBackButton = true,
  children,
  currentPage,
}) => {
  const { t: translate } = useTranslation('SlidePage');

  return (
    <ContainerSlide currentPage={currentPage}>
      <ContentSlide>
        {showBackButton && (
          <YIconButton
            className="previous_btn"
            size="lg"
            contained
            aria-label={translate('ButtonBackAlt')}
            title={translate('ButtonBackAlt')}
            onClick={() => handleChangePage(false)}
            children={<YIcon name="chevronLeft" />}
          />
        )}
        {children}
        {showNextButton && (
          <YIconButton
            className="next_btn"
            size="lg"
            contained
            aria-label={translate('ButtonNextAlt')}
            title={translate('ButtonNextAlt')}
            onClick={() => handleChangePage(true)}
            children={<YIcon name="chevronRight" />}
          />
        )}
      </ContentSlide>
    </ContainerSlide>
  );
};

const SlidePage: React.FC<ISlidePage> = ({
  currentPage,
  nextPage,
  previousPage,
  handleExport,
  isPrintActive,
  formattedData,
}) => {
  return (
    <>
      <Container isPrintActive={isPrintActive}>
        <Slide
          handleChangePage={(next) => (next ? nextPage() : previousPage())}
          showNextButton={!isPrintActive && currentPage < 6}
          currentPage={currentPage}
          showBackButton={!isPrintActive}
        >
          {currentPage === 0 && <WelcomeSlide isPrintActive={isPrintActive} />}
          {currentPage === 1 && (
            <WordsSlide
              isPrintActive={isPrintActive}
              data={formattedData.translatedWords}
            />
          )}
          {currentPage === 2 && (
            <UsersSlide isPrintActive={isPrintActive} data={formattedData.users} />
          )}
          {currentPage === 3 && (
            <PagesSlide isPrintActive={isPrintActive} data={formattedData.topPages} />
          )}
          {currentPage === 4 && (
            <TopAddonsSlide
              isPrintActive={isPrintActive}
              data={formattedData.topAddons}
            />
          )}
          {currentPage === 5 && <ShareResultSlide handleExport={handleExport} />}
        </Slide>
      </Container>
    </>
  );
};

export default SlidePage;
