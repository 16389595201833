type BadgeProps = {
  className?: string;
};

const BadgeAMillion: React.FC<BadgeProps> = () => {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.4292 56.1763L10.0527 109.639L35.8211 107.258L48.293 129.935L76.6695 76.4725L38.4292 56.1763Z"
        fill="#E95454"
      />
      <path
        d="M41.6792 114.368C42.4755 112.657 41.6427 110.614 39.977 109.728L38.3439 108.859C36.7108 107.928 36.0892 105.861 36.9789 104.187C37.8808 102.485 40.0014 101.835 41.7036 102.737L46.3186 105.186C47.7445 105.942 49.532 105.393 50.2673 103.959L50.3811 103.74C51.2261 102.387 50.7264 100.6 49.3208 99.8563L35.5164 92.5316C33.8345 91.6378 33.0098 89.6025 33.7939 87.8881C34.6592 86.0641 36.8448 85.345 38.6039 86.2794L51.4089 93.0759C52.8348 93.8316 54.6223 93.2831 55.378 91.8572L55.4511 91.6703L55.4714 91.6297C56.288 90.1347 55.7152 88.2578 54.208 87.4575L47.0255 83.6469C45.3436 82.7531 44.5189 80.7178 45.3152 78.9831C46.1723 77.1794 48.3661 76.44 50.1252 77.3744L58.0592 81.5872C59.542 82.3753 61.3133 81.7172 62.0811 80.2222C62.0933 80.2019 62.0933 80.2019 62.1014 80.1816C62.1136 80.1613 62.1136 80.1613 62.1217 80.1409C62.9302 78.6663 62.4833 76.83 60.9964 76.0459L52.4367 71.5041C50.7752 70.6225 49.9505 68.5872 50.7345 66.8728C51.5998 65.0488 53.7855 64.3297 55.5445 65.2641L76.6614 76.4725L48.2848 129.935L40.4645 115.72C40.9683 115.379 41.407 114.957 41.6792 114.368Z"
        fill="#ED6362"
      />
      <path
        d="M91.5098 56.1763L119.886 109.639L94.118 107.258L81.6461 129.935L53.2695 76.4725L91.5098 56.1763Z"
        fill="#ED6362"
      />
      <path
        d="M88.2598 114.368C87.4636 112.657 88.2964 110.614 89.962 109.728L91.5951 108.859C93.2283 107.928 93.8498 105.861 92.9602 104.187C92.0583 102.485 89.9377 101.835 88.2355 102.737L83.6205 105.186C82.1945 105.942 80.407 105.393 79.6717 103.959L79.558 103.74C78.713 102.387 79.2127 100.6 80.6183 99.8562L94.4267 92.5275C96.1086 91.6337 96.9333 89.5984 96.1492 87.8841C95.2839 86.06 93.0983 85.3409 91.3392 86.2753L78.5342 93.0719C77.1083 93.8275 75.3208 93.2791 74.5652 91.8531L74.492 91.6662L74.4717 91.6256C73.6551 90.1306 74.228 88.2538 75.7352 87.4534L82.9177 83.6428C84.5995 82.7491 85.4242 80.7137 84.628 78.9791C83.7708 77.1753 81.577 76.4359 79.818 77.3703L71.8758 81.5912C70.393 82.3794 68.6217 81.7212 67.8539 80.2262C67.8417 80.2059 67.8417 80.2059 67.8336 80.1856C67.8214 80.1653 67.8214 80.1653 67.8133 80.145C67.0048 78.6703 67.4517 76.8341 68.9386 76.05L77.4983 71.5081C79.1598 70.6266 79.9845 68.5912 79.2005 66.8769C78.3352 65.0528 76.1495 64.3337 74.3905 65.2681L53.2736 76.4766L81.6502 129.939L89.4705 115.724C88.9708 115.379 88.532 114.957 88.2598 114.368Z"
        fill="#E95454"
      />
      <path
        d="M64.9695 94.8269C91.1553 94.8269 112.383 73.5992 112.383 47.4134C112.383 21.2277 91.1553 0 64.9695 0C38.7838 0 17.5561 21.2277 17.5561 47.4134C17.5561 73.5992 38.7838 94.8269 64.9695 94.8269Z"
        fill="#FFCC5B"
      />
      <path
        d="M71.4005 41.9737H41.3542C39.0183 41.9737 37.1008 40.0562 37.1008 37.7203L37.1333 37.4278V37.3628C37.1008 34.8969 39.1117 32.8819 41.5817 32.8819H62.4061C65.1645 32.8819 67.602 30.8384 67.7645 28.0759C67.8945 25.1875 65.5911 22.75 62.6986 22.75H40.6392C38.2058 22.75 36.3858 20.7066 36.4183 18.2691V18.2041V18.1391C36.3858 15.7056 38.2017 13.6581 40.6392 13.6581H63.7223C66.4483 13.6581 68.8858 11.6147 69.0483 8.88468C69.1783 5.96375 66.8748 3.55875 63.9823 3.55875H47.0783C29.8411 10.6641 17.6942 27.6128 17.6942 47.4134C17.6942 67.2141 29.8411 84.1587 47.0823 91.2559H68.5445C71.2705 91.2559 73.708 89.2125 73.8705 86.4825C74.0005 83.5616 71.697 81.1606 68.8045 81.1606L44.3889 81.1566C41.5086 81.1566 39.1889 78.7475 39.3433 75.8306C39.4855 73.1006 41.9392 71.0491 44.6733 71.0491H54.1389C56.8608 70.9719 59.0586 68.7416 59.0586 65.9994C59.0586 63.2084 56.7877 60.9334 53.9926 60.9334H39.6439C37.308 60.9334 35.3905 59.02 35.423 56.68V56.3225C35.2605 54.0191 37.113 52.0691 39.4164 52.0691H71.108C73.8664 52.0691 76.3039 50.0256 76.4664 47.2956C76.5964 44.3787 74.2889 41.9737 71.4005 41.9737Z"
        fill="#FDBC4B"
      />
      <path
        d="M64.9695 81.2541C46.3064 81.2541 31.1248 66.0684 31.1248 47.4094C31.1248 28.7503 46.3064 13.5688 64.9695 13.5688C83.6327 13.5688 98.8142 28.7544 98.8142 47.4134C98.8142 66.0725 83.6327 81.2541 64.9695 81.2541Z"
        fill="#FFDB70"
      />
      <path
        opacity="0.2"
        d="M98.8142 47.4134C98.8142 29.4084 84.6686 14.6819 66.9114 13.6662H63.7589C61.6789 13.6662 59.8223 15.2262 59.6964 17.3062C59.5989 19.5325 61.3539 21.3687 63.5599 21.3687L82.1823 21.3728C84.3802 21.3728 86.1474 23.2091 86.0295 25.4353C85.9198 27.5194 84.0511 29.0834 81.963 29.0834H74.7439C72.668 29.1444 70.9902 30.8425 70.9902 32.9347C70.9902 35.0634 72.7249 36.7981 74.8536 36.7981H85.798C87.5814 36.7981 89.0399 38.2606 89.0155 40.04V40.3081C89.1374 42.0672 87.7277 43.55 85.9686 43.55H61.8008C59.6964 43.55 57.8398 45.11 57.7139 47.19C57.6164 49.4162 59.3714 51.2525 61.5774 51.2525H84.4899C86.2733 51.2525 87.7317 52.715 87.7317 54.4944L87.7073 54.7178V54.7666C87.7317 56.6475 86.1961 58.1831 84.3152 58.1831H68.4308C66.3264 58.1831 64.4698 59.7431 64.3439 61.8475C64.2464 64.0494 66.0014 65.91 68.2073 65.91H85.0302C86.8867 65.91 88.272 67.47 88.2477 69.3266V69.4241C88.272 71.2806 86.8867 72.8406 85.0302 72.8406H67.4273C65.3473 72.8406 63.4908 74.4006 63.3648 76.4806C63.2673 78.7109 65.0223 80.5431 67.2283 80.5431H71.8717C87.2402 77.3419 98.8142 63.7041 98.8142 47.4134Z"
        fill="white"
      />
      <path
        d="M88.597 39.9181H72.1358L67.0455 24.2612C66.3914 22.2503 63.5436 22.2503 62.8895 24.2612L57.8033 39.9181H41.342C39.2255 39.9181 38.3439 42.6278 40.0583 43.8709L53.3752 53.5478L48.2889 69.2047C47.6348 71.2156 49.9383 72.8894 51.6527 71.6462L64.9695 61.9694L78.2864 71.6462C80.0008 72.8894 82.3042 71.2156 81.6502 69.2047L76.5639 53.5478L89.8808 43.8709C91.5911 42.6278 90.7136 39.9181 88.597 39.9181Z"
        fill="#EC9922"
      />
    </svg>
  );
};

export default BadgeAMillion;
