import { injectScript } from './domUtils';

const dataLayer = function (...data: any[]) {
  // @ts-ignore
  const dl = window.dataLayer || [];
  dl.push(arguments);
};

let isInitialized = false;

export const init = async (trackCode: string): Promise<any> => {
  if (isInitialized) return;

  const url = 'https://www.googletagmanager.com/gtag/js?id=' + trackCode;
  await injectScript(document.body, url);
  dataLayer('js', new Date());

  const configOptions: Record<string, any> = {};

  dataLayer('config', trackCode, configOptions);
  isInitialized = true;
};

export const sendEvent = async (
  eventName: string,
  category: string,
  label?: string
): Promise<void> => {
  if (!isInitialized) return;

  const eventData = {
    event_category: category,
    event_label: label,
  };

  dataLayer('event', eventName, eventData);
};

export const accessCounterEvent = async (domain: string) => {
  await sendEvent('Contador De Acesso', 'Contador', domain);
};

export const invalidLoginEvent = async (): Promise<void> => {
  await sendEvent('Login Inválido', 'Autenticação');
};

export const loginEvent = async (): Promise<void> => {
  await sendEvent('Login Realizado', 'Autenticação');
};

export const logoutEvent = async (): Promise<void> => {
  await sendEvent('Logout realizado', 'Autenticação');
};

export const dashboardAccessEvent = async (domain: string): Promise<void> => {
  await sendEvent('Clique no botão acessar dashboard', 'Navegação', domain);
};

export const clickBackToDomainListsEvent = async (): Promise<void> => {
  await sendEvent('Clique no botão voltar para lista de domínios', 'Navegação');
};

export const clickDomainInDropdownsEvent = async (domain: string): Promise<void> => {
  await sendEvent('Seleção de dashboard no dropdown', 'Navegação', domain);
};

export const clickOnDomainLinksEvent = async (domain: string): Promise<void> => {
  await sendEvent('Clique no link conferencia de domínio', 'Navegação', domain);
};
