/** Libs */
import { inject, injectable } from 'inversify';

/** Domain */
import ReportRepository from '../../infra/repositories/ReportRepository';

/** Types */
import { IReportInsight, IPeriod, SourceType } from '../../infra/services/interfaces';

@injectable()
export default class FilteredInsightReportUseCase {
  @inject(ReportRepository) private reportRepository: ReportRepository;

  public async execute(
    token: string,
    period: IPeriod,
    source: SourceType,
    limit: number = 10,
    offset: number = 0,
    filterContent?: string
  ): Promise<IReportInsight> {
    return await this.reportRepository.filteredInsight(
      token,
      period,
      source,
      limit,
      offset,
      filterContent
    );
  }
}
