// Libs
import { useCallback, useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import { User } from 'firebase/auth';

// Services
import AuthRepository from '../../infra/repositories/UserRepository';

interface IAuthResult {
  type: 'loading' | 'none';
}

interface IAuthError {
  type: 'error';
  message: string;
}

interface IAuthSuccess {
  type: 'success';
  user: User;
}

export type AuthState = IAuthError | IAuthSuccess | IAuthResult;

export default function useAuth() {
  const authRepository = useInjection(AuthRepository);

  const [authState, setAuthState] = useState<AuthState>({ type: 'loading' });

  useEffect(() => {
    const unsubscribe = authRepository.addAuthChangeListener((user) => {
      if (user) {
        setAuthState({ type: 'success', user });
      } else {
        setAuthState({ type: 'none' });
      }
    });
    return unsubscribe;
  }, [authRepository]);

  const signIn = useCallback(
    async (email: string, password: string) => {
      try {
        setAuthState({ type: 'loading' });
        await authRepository.signIn(email, password);
      } catch (error) {
        const { message } = error as Error;
        setAuthState({ type: 'error', message });
      }
    },
    [authRepository]
  );

  const signOut = useCallback(async () => {
    try {
      setAuthState({ type: 'loading' });
      await authRepository.signOut();
      setAuthState({ type: 'none' });
    } catch (error) {
      const { message } = error as Error;
      setAuthState({ type: 'error', message });
    }
  }, [authRepository]);

  return { signIn, signOut, authState };
}
