/** Library */
import React from 'react';
import i18n from '../../../../infra/i18n';

/** Services */
import { formatDashboardValue, formatDateRange } from '../../../../infra/services/helper';

/** Interfaces */
import { IAverageReportSchema } from '../../../../infra/services/interfaces';

/** Components */
import TopContainer from '../../../components/TopContainer';
import Overview from '../../../components/Overview';
import Chart from '../../../components/Chart';
import UserTable from '../../../components/UserTable';
import TopAddons from '../../../components/TopAddons';
import Achievement from '../../../components/Achievement';
import ChartUsers from '../../../components/ChartUsers';
import { UserInfoState } from '../../../hooks/useUserInfo';

type TAverageReport = {
  averageReport: IAverageReportSchema;
  isAverage: boolean;
  filter: JSX.Element;
  handleExportCSV?: () => void;
  handleExportPDF: () => void;
  userInfoState: UserInfoState;
};

/**
 * Renderiza o conteúdo do relatório único.
 */
const AverageReport: React.FC<TAverageReport> = ({
  averageReport,
  isAverage,
  filter,
  handleExportCSV,
  handleExportPDF,
  userInfoState,
}) => {
  const schema = averageReport;

  const { period, info, achievements, translationHistory, averageInsight } = schema;

  const { domain, filterLanguages, token } = info;
  const { translatedWordsAccumulated } = achievements;
  const { signLanguage, translatedWords } = translationHistory;
  const {
    averageTopDevices,
    averageTopStates,
    averageTopPages,
    topAddons,
    addons,
    averageUsers,
    monthlyUsersInfo,
  } = averageInsight;

  const periodFormattedFull = formatDateRange(period, i18n.language);

  const dataTopAddons =
    topAddons && topAddons.length > 0 ? topAddons.sort((a, b) => b.value - a.value) : [];
  const dataAverageTopDevices =
    averageTopDevices && averageTopDevices.length > 0
      ? averageTopDevices.sort((a, b) => b.value - a.value)
      : [];
  const dataAverageTopState =
    averageTopStates && averageTopStates.length > 0
      ? averageTopStates.sort((a, b) => b.value - a.value).slice(0, 10)
      : [];
  const dataAverageTopPages =
    averageTopPages && averageTopPages.length > 0
      ? averageTopPages.sort((a, b) => b.value - a.value).slice(0, 10)
      : [];

  const data = {
    translations: formatDashboardValue(translatedWords).formatted,
    users: formatDashboardValue(averageUsers).formatted,
    addons: formatDashboardValue(addons && addons > 0 ? addons : 0).formatted,
  };

  return (
    <>
      <TopContainer
        onClickExportCsv={handleExportCSV}
        onClickExportPdf={handleExportPDF}
        filter={filter}
        domain={domain}
        addons={data.addons}
        report={averageReport}
        userInfoState={userInfoState}
      />
      <Overview isAverageReport={isAverage} data={data} period={periodFormattedFull} />
      <Chart
        translations={{ signLanguage, translatedWords }}
        filterLanguages={filterLanguages}
      />
      <TopAddons data={dataTopAddons} period={periodFormattedFull} />
      <ChartUsers monthlyUsersInfo={monthlyUsersInfo} averageUsers={averageUsers} />
      <UserTable
        token={token}
        topStates={dataAverageTopState}
        topDevices={dataAverageTopDevices}
        topPages={dataAverageTopPages}
        periodFormattedFull={periodFormattedFull}
        period={period}
        domain={domain}
        isAverage={isAverage}
      />
      <Achievement translationsAccumulated={translatedWordsAccumulated} />
    </>
  );
};

export default AverageReport;
