/** Interfaces */
import { isRouteValid } from '../../../infra/services/helper';
import { IUrlLinkProps } from '../../../infra/services/interfaces';

const formatRoute = (route: string) => {
  const len = route.length;
  if (route[0] === '/') {
    return route.substring(1, len);
  }

  return route;
};

const UrlLink = (props: IUrlLinkProps) => {
  const { domain, route } = props;

  const isPossible = isRouteValid(domain, route);

  if (isPossible) {
    return (
      <a href={domain + formatRoute(route)} target="_blank" rel="noopener noreferrer">
        {route}
      </a>
    );
  }

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a>{route}</a>;
};

export default UrlLink;
