// Libs
import React from 'react';
import { YInput, YButton } from '@hand-talk/yotta-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components
import HeaderForm from '../HeaderForm';

// Styles
import { Form } from './styles';

export interface IResetPasswordFormProps {
  onSubmit?: (email: string) => void;
  success?: boolean;
}

const ResetPassword: React.FC<IResetPasswordFormProps> = ({ onSubmit, success }) => {
  const { t } = useTranslation('AuthForms');
  const navigate = useNavigate();

  const titleText = t(success ? 'resetPasswordSuccessTitle' : 'resetPasswordTitle');
  const subText = t(success ? 'resetPasswordSuccessSubtitle' : 'resetPasswordSubtitle');

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const email = event.currentTarget['email'].value as string;
      onSubmit?.(email);
    },
    [onSubmit]
  );

  const form = (
    <Form onSubmit={handleSubmit}>
      <YInput
        labelText={t('email')}
        inputProps={{
          name: 'email',
          placeholder: t('emailPlaceholder'),
          type: 'email',
          required: true,
        }}
      />
      <YButton style={{ marginTop: 24 }} size="total">
        {t('submitEmail')}
      </YButton>
      <YButton
        aria-label={t('backLogin')}
        variation="text"
        style={{ marginTop: 8 }}
        size="total"
        onClick={() => (window.location.href = '/')}
      >
        {t('back')}
      </YButton>
    </Form>
  );

  const successContent = (
    <Form>
      <YButton
        aria-label={t('backLogin')}
        variation="text"
        size="total"
        onClick={() => navigate('/')}
      >
        {t('backLogin')}
      </YButton>
    </Form>
  );

  return (
    <>
      <HeaderForm
        title={titleText}
        subtitle={subText}
        success={success}
        maxWidth={success}
      />
      {success ? successContent : form}
    </>
  );
};

export default ResetPassword;
