export default class Entity<T> {
  protected _id: string;
  protected _schema: T;

  public get id() {
    return this._id;
  }

  /** Converte o modelo num objeto transportavel */
  public toObject = () => {
    return { ...this._schema };
  };

  public toObjectWithId = () => {
    return { ...this._schema, id: this._id };
  };

  constructor(id: string, schema: T) {
    this._id = id;
    this._schema = schema;
  }
}
