/** Libs */
import styled from 'styled-components';

/** Tokens */
import { brand } from '../../assets/tokens/colors';

/** Services */
import { tabletMediaQuery } from '../../../infra/services/helper';

export const StyleLine = styled.div<{ double?: boolean }>`
  width: 24px;

  .gray {
    background-color: #bdbdbd;
    display: inline-block;
  }

  .circle {
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }

  .line {
    width: 6px;
    height: 180px;
  }

  @media (max-width: 1239px) {
    > div:nth-child(1) {
      display: ${(props) => (props.double ? 'none' : 'initial')};
    }

    > div:nth-child(2),
    div:nth-child(3) {
      display: none;
    }
  }
`;

export const Section = styled.section`
  padding: ${({ theme }) => theme.spacing[8]} 0;

  ${tabletMediaQuery(`
    .LineMobile {
      background-color:  #bdbdbd;
      display: inline-block;
      width: 6px;
      height: 180px;
    }
  `)}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  max-width: 700px;

  font: ${({ theme }) => theme.typography.display.lg};
  color: ${({ theme }) => theme.colors.primary.default[0]};
  text-align: center;
`;

export const SubTitle = styled.p`
  max-width: 1100px;

  font-size: ${({ theme }) => theme.font.size['04']};
  font-weight: 900;
  font-style: italic;
  line-height: 160%;
  padding-top: ${({ theme }) => theme.spacing[7]};
  text-align: center;
`;

export const Description = styled.p`
  max-width: 860px;

  line-height: 160%;

  font-size: ${({ theme }) => theme.font.size['04']};
  font-weight: 400;

  padding-bottom: ${({ theme }) => theme.spacing[8]};
  text-align: center;
`;

export const StyleCard = styled.div<{ direction: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.direction === 'left' ? 'row-reverse' : 'undefined'};

  ${tabletMediaQuery(`
    flex-direction: column;
  `)}
`;

export const Month = styled.div<{ double?: boolean }>`
  width: 170px;
  height: auto;
  text-align: center;
  display: ${(props) => (props.double ? 'none' : 'initial')};

  text-transform: uppercase;

  background-color: ${({ theme }) => theme.colors.primary.default[0]};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.primary.on[0]};
  font: ${({ theme }) => theme.typography.title.md};
`;

export const Row = styled.div`
  width: 170px;
`;

export const Space = styled.div`
  width: 356px;

  ${tabletMediaQuery(`
    display: none;
  `)}
`;

export const Events = styled.div<{ noUrl?: boolean }>`
  width: 356px;
  height: auto;
  padding: 28px;
  border-radius: 16px;
  border: solid 2px ${brand.color.new};
  margin-top: 60px;

  background-color: ${({ theme }) => theme.colors.surface.dim[1][0]};

  button {
    display: ${(props) => (props.noUrl ? 'none' : 'initial')};
    margin-top: 16px;
  }

  ${tabletMediaQuery(`
     max-width: 356px;
     width: 100%;
     margin-top: 0;
  `)}
`;

export const TitleCard = styled.p`
  font: ${({ theme }) => theme.typography.title.md};
  color: ${({ theme }) => theme.colors.primary.default[0]};
`;

export const Text = styled.p`
  font: ${({ theme }) => theme.typography.paragraph.md};
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};
`;
